import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Configurator from "./configurator/Configurator";
import { ErrorToast } from "./components/ErrorToast";
import { NavMenu } from "./nav/NavMenu";
import { Sidebar } from "./sidebar/Sidebar";
import { Wizard } from "./wizard/Wizard";
import { actions } from "./configurator/slice";
import { useAppDispatch, useAppSelector } from "./store";
import { isExpired } from "react-jwt";

import "./custom.css"

const App = () => {
    const dispatch = useAppDispatch();
    const configID = useAppSelector(state => state.configurator.configurationId);
    const loggedIn = useAppSelector(state => state.configurator.loggedIn);

    React.useEffect(() => {
        var token = new URLSearchParams(window.location.search).get("token");

        if(token){
            dispatch(actions.login({token: token}));
            window.location.assign("/");
        }else{
            token = localStorage.getItem('token');
            if (token) {
                if (isExpired(token)) {
                    localStorage.removeItem('token');
                } else {
                    dispatch(actions.login({ token: token }));
                }
            }
        }
    });

    React.useEffect(() => {
        if(configID && loggedIn){
            dispatch(actions.addConfigurationToMe({configurationId: configID}));
        }
    }, [configID, loggedIn]);

    return (<div>
        <NavMenu />

        <Container className="vh-100" fluid>
            <Row className="h-100">
                <Sidebar />
                <Configurator />
            </Row>
        </Container>

        <Wizard />
        <ErrorToast />
    </div>);
};

export default App;
