import * as React from "react";
import { IconType } from "react-icons";

export const IconLabel: React.FC<{ iconClassName?: string, icon: IconType, children?: React.ReactNode }> = ({
    children,
    icon,
    iconClassName,
}) => (
    <div className="h-100 d-flex gap-1 align-items-stretch">
        {icon({ className: `align-self-center ${iconClassName ?? ""}` })}
        {children}
    </div>
);
