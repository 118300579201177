import * as React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

export const ErrorToast = () => {
    const [error, setError] = React.useState<any | null>(null);

    React.useEffect(() => {
        const handler = (event: PromiseRejectionEvent) => {
            event.preventDefault(); // don't log to console
            setError(event.reason);
        };

        window.addEventListener("unhandledrejection", handler);

        return () => {
            window.removeEventListener("unhandledrejection", handler);
        };
    })

    return (
        <ToastContainer className="position-fixed p-3" position="bottom-end" style={{ zIndex: 1080 }}>
            <Toast onClose={() => setError(null)} show={error !== null}>
                <Toast.Header>
                    <strong className="me-auto">Fejl!</strong>
                </Toast.Header>

                <Toast.Body>
                    <p>
                        Der skete en fejl 😢 Prøv igen
                    </p>

                    <p className="error-message overflow-auto">
                        <code>{error?.message}</code>
                    </p>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    );
};
