import * as React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { BsFillEnvelopeFill } from "react-icons/bs";
import ReactGA from 'react-ga4';

import { IconLabel } from "../components/IconLabel";
import { PrivacyPolicy, TermsAndConditions } from "../components/Conditions";

export const RequestQuote: React.FC<{
    configurationId: string;
    onHide: () => void;
    show: boolean;
}> = ({
    configurationId,
    onHide,
    show,
}) => {
    const [busy, setBusy] = React.useState(false);
    const [error, setError] = React.useState<unknown | null>(null);
    const [validated, setValidated] = React.useState(false);

    const onExited = () => {
        setBusy(false);
        setError(false);
        setValidated(false);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.target as HTMLFormElement;

        if (form?.checkValidity()) {
            setBusy(true);
            setError(null);

            ReactGA.event('request_offer', {
                
            });

            try {
                const response = await fetch("api/quote", {
                    body: new FormData(form),
                    method: "POST",
                });

                if (!response.ok) {
                    throw new Error(await response.text());
                }

                onHide();
            } catch (error) {
                setError(error);
            } finally {
                setBusy(false);
            }
        }

        setValidated(true);
    };

    return (
        <Modal backdrop={busy ? "static" : true} keyboard={!busy} onExit={onHide} onExited={onExited} onHide={onHide} scrollable show={show} size="lg">
            <Modal.Header closeButton={!busy}>
                <Modal.Title>
                    Anmod om tilbud
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form className={`px-3 pb-3 ${validated ? "was-validated" : ""}`} noValidate onSubmit={onSubmit}>
                    <Form.Control name="ConfigurationId" type="hidden" value={configurationId} />

                    <Form.Text as="div" className="mb-4 form-text">
                        Udfyld formularen for at anmode om et tilbud
                    </Form.Text>

                    <Form.Group className="mb-3">
                        <Form.Control
                            maxLength={100}
                            name="Project"
                            placeholder="Projekt*"
                            required
                            type="text" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            maxLength={100}
                            name="Name"
                            placeholder="Navn / Virksomhed*"
                            required
                            type="text" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            maxLength={100}
                            name="Company"
                            placeholder="Virksomhed (valgfrit)"
                            type="text" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            maxLength={255}
                            name="EmailAddress"
                            placeholder="E-mailadresse*"
                            required
                            type="email" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            maxLength={30}
                            name="PhoneNumber"
                            placeholder="Telefon*"
                            required
                            type="tel" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            maxLength={100}
                            name="Address"
                            placeholder="Adressse*"
                            required
                            type="text" />
                    </Form.Group>

                    <Row className="mb-3">
                        <Col className="col-4">
                            <Form.Control
                                maxLength={4}
                                name="PostCode"
                                placeholder="Postnummer*"
                                required
                                type="text" />
                        </Col>

                        <Col className="col-8">
                            <Form.Control
                                maxLength={100}
                                name="City"
                                placeholder="By*"
                                required
                                type="text" />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col>
                            <Form.Label className="col-form-label" htmlFor="formFile">
                                Upload et billede af opsætningsområde (valgfrit)
                            </Form.Label>
                        </Col>

                        <Col>
                            <Form.Control id="formFile" name="image" type="file" />
                        </Col>
                    </Row>

                    <Form.Group className="mb-3">
                        <Form.Control
                            min={1}
                            name="YearsToRent"
                            placeholder="I hvor mange år ønsker De at leje*"
                            required
                            type="number" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Check
                            className="form-check"
                            id="terms"
                            type="checkbox">
                            <Form.Check.Input name="Terms" required value="true" />
                            <Form.Check.Label>
                                <span className="text-secondary">
                                    Jeg accepterer <TermsAndConditions>betingelserne</TermsAndConditions>
                                </span>
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Control
                            name="Terms"
                            type="hidden"
                            value="false" />
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Check
                            className="form-check"
                            id="privacy"
                            type="checkbox">
                            <Form.Check.Input name="Privacy" value="true" />
                            <Form.Check.Label>
                                <span className="text-secondary">
                                    Jeg giver tilladelse til, at CP må kontakte mig angående min konfiguration (<PrivacyPolicy>persondatapolitik</PrivacyPolicy>)
                                </span>
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Control
                            name="Privacy"
                            type="hidden"
                            value="false" />
                    </Form.Group>

                    <div className="d-flex gap-3 align-items-center">
                        <Button className="h-100" disabled={busy} type="submit" variant="outline-primary">
                            <IconLabel icon={BsFillEnvelopeFill}>
                                {busy ? "Anmoder..." : "Anmod om tilbud"}
                            </IconLabel>
                        </Button>

                        <>{error && (<span className="text-danger">Der skete en fejl. Venligst prøv igen</span>)}</>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
