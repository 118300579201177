import * as React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import ContentEditable from "react-contenteditable";
import { BsArrowClockwise, BsCheck, BsPencil, BsTrashFill, BsType, BsCheck2, BsArrowsMove } from "react-icons/bs";
import { CgEditFlipH, CgEditFlipV } from "react-icons/cg";
import { BiRotateLeft } from "react-icons/bi";
import { MdOutlineChair } from "react-icons/md";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { ConfiguredModuleView } from "./ConfiguredModule";
import { CustomizationView } from "./Customization";
import { LabelProps, LabelView } from "./Label";
import { PlacedFurnitureView } from "./PlacedFurniture";

import { actions } from "./slice";

import { IconLabel } from "../components/IconLabel";
import { ShakingButton } from "../components/Shake";

import { StaticDataContext } from "../contexts";
import { useAppDispatch, useAppSelector } from "../store";
import { ConfiguredModule, Customization, Hotspot, HotspotOption, Label, PlacedFurniture } from "../types";

interface CustomizeModuleContainerProps {
    configuredModule: ConfiguredModule;
    floorName: string;

    onCreateLabel: (configuredModuleId: string, text: string, x: number, y: number, rotation: number) => void;
    onFlipX: (customizationId: string) => void;
    onFlipY: (customizationId: string) => void;
    onRotateCustomization: (customizationId: string) => void;
    onInsertFurniture: (configuredModuleId: string, furnitureId: string, x: number, y: number, rotation: number) => void;
    onMoveFurniture: (placedFurnitureId: string, x: number, y: number) => void;
    onOptionSelected: (configuredModuleId: string, hotspotId: string, optionId: string | null) => void;
    onRemove: (configuredModuleId: string) => void;
    onRotate: (configuredModuleId: string) => void;
    onUpdateLabel: (labelId: string, text: string, x: number, y: number, rotation: number) => void;
}

interface CustomizationMode {
    kind: "customization";
    customizationId: string;
}

interface FurnitureMode {
    kind: "furniture";
    furnitureId: string | null;
}

interface OptionsMode {
    kind: "options";
    hotspotId: string | null;
    optionId?: string | null;
}

interface PlacedFurnitureMode {
    kind: "placedFurniture";
    placedFurnitureId: string;
}

interface TextMode {
    kind: "text";
    text: TextState | null;
}

interface TextState {
    id: string | null;
    text: string;
    x: number;
    y: number;
    rotationDegrees: number;
}

type Mode = CustomizationMode | FurnitureMode | OptionsMode | PlacedFurnitureMode | TextMode;

const defaultMode: Mode = {
    kind: "options",
    hotspotId: null,
};

export const CustomizeModuleContainer: React.FC<CustomizeModuleContainerProps> = React.memo(({
    configuredModule,
    floorName,

    onCreateLabel,
    onFlipX,
    onFlipY,
    onRotateCustomization,
    onInsertFurniture,
    onMoveFurniture,
    onOptionSelected,
    onRemove,
    onRotate,
    onUpdateLabel,
}) => {
    const { customizations, floor, id, index, labels, moduleId, placedFurnitures, rotationDegrees } = configuredModule;
    const cmRef = React.useRef<HTMLDivElement>(null);

    const [mode, setMode] = React.useState<Mode>({ ...defaultMode });

    const staticData = React.useContext(StaticDataContext);

    const { furnitures } = staticData;

    const module = React.useMemo(
        () => staticData.modules.find(m => m.id === moduleId)!,
        [moduleId]);

        
    let groundFloorHeight = useAppSelector(state => Math.max(0, ...state
        .configurator
        .configuredModules
        .filter(e => e.floor == 0)
        .map(cm => staticData.modules.find(m => m.id === cm.moduleId)!.heightGroundFloorMm)));

    let recommendedStairs = "";
    if(floor == 1){
        if(groundFloorHeight == 3190){
            recommendedStairs = "Vi anbefaler 16 trins trappe til denne konfiguration"
        }
        if(groundFloorHeight == 2791){
            recommendedStairs = "Vi anbefaler 14 trins trappe til denne konfiguration"
        }
    }



    const hotspots = React.useMemo(
        () => staticData.hotspots
            .filter(hs => hs.moduleId === moduleId)
            .filter(hs => {
                const hotspotOptions = staticData.hotspotOptions.filter(ho => ho.hotspotId === hs.id);
                const options = hotspotOptions.flatMap(ho => staticData.options.filter(o => ho.optionId === o.id)).filter(e => e.image !== "");

                return options.length !== 0 && options.some(o => o.floors.includes(floor));
            }),
        [floor, moduleId]);

    const hotspotOptions = React.useMemo(
        () => staticData.hotspotOptions
            .filter(ho => mode.kind === "options" && ho.hotspotId === mode.hotspotId)
            .filter(ho =>staticData.options
                .filter(o => o.id === ho.optionId)
                .map(o => o.floors)[0]
                .includes(floor)),
        [floor, mode]);

    const click = React.useCallback((e: React.MouseEvent) => {
        setMode(m => {
            if (m.kind === "text") {
                if (m.text) {
                    return { ...m };
                } else {
                    const rect = cmRef.current!.getBoundingClientRect();

                    const [x, y] = rotateDeg(
                        0.5, 0.5,
                        (e.clientX - rect.left) / rect.width,
                        (e.clientY - rect.top) / rect.height,
                        rotationDegrees);

                    return {
                        ...m,
                        text: {
                            id: null,
                            text: " ",
                            x,
                            y,
                            rotationDegrees: 0,
                        }
                    };
                }
            } else {
                return m;
            }
        });
    }, [rotationDegrees]);

    const dropFurniture: React.DragEventHandler = React.useCallback((event) => {
        event.preventDefault();

        const rect = cmRef.current!.getBoundingClientRect();

        const [x, y] = rotateDeg(
            0.5, 0.5,
            (event.clientX - rect.left) / rect.width,
            (event.clientY - rect.top) / rect.height,
            rotationDegrees);

        const { dataTransfer } = event;
        const json = dataTransfer.getData("text/plain");

        let parsedId: string;

        try {
            parsedId = JSON.parse(json);
        } catch {
            return;
        }

        onInsertFurniture(id, parsedId, x, y, rotationDegrees);
    }, [id, onInsertFurniture, rotationDegrees]);

    const getBoundingClientRect = () => {
        return cmRef.current?.getBoundingClientRect() ?? new DOMRect(undefined, undefined, undefined, undefined);
    };

    const onPlacedFurnitureRemoved = React.useCallback(() => {
        setMode({ ...defaultMode });
    }, []);

    const selectCustomization = React.useCallback((customizationId: string) => {
        setMode(m => {
            if (m.kind === "customization") {
                if (m.customizationId === customizationId) {
                    return { ...defaultMode };
                } else {
                    return { ...m, customizationId };
                }
            } else {
                return { kind: "customization", customizationId };
            }
        });
    }, []);

    const selectFurnitureMode = React.useCallback(() => {
        setMode(m => {
            if (m.kind === "furniture") {
                return { ...defaultMode };
            } else {
                return { kind: "furniture", furnitureId: null };
            }
        })
    }, []);

    const selectHotspot = React.useCallback((hotspotId: string) => {
        setMode(m => {
            if (m.kind === "options" && m.hotspotId === hotspotId) {
                return { ...m, hotspotId: null, optionId: undefined };
            } else {
                const selectedOptions = customizations
                    .filter(c => c.hotspotId === hotspotId && c.optionId);

                const optionId = staticData.hotspotOptions
                    .filter(ho => ho.hotspotId === hotspotId)
                    .map(ho => ho.optionId)
                    .find(id => selectedOptions.some(c => c.optionId === id));

                return { kind: "options", hotspotId, optionId };
            }
        });
    }, [customizations]);

    const selectOption = React.useCallback((optionId: string) => {
        setMode(m => {
            if (m.kind === "options") {
                if (m.optionId === optionId) {
                    return { ...m, optionId: null };
                } else {
                    return { ...m, optionId };
                }
            } else {
                return m;
            }
        })
    }, []);

    const selectPlacedFurniture = React.useCallback((placedFurnitureId: string) => {
        setMode(m => {
            if (m.kind === "placedFurniture") {
                return { ...m, placedFurnitureId };
            } else {
                return { kind: "placedFurniture", placedFurnitureId };
            }
        });
    }, []);

    const selectLabel = React.useCallback((text: Label | TextState) => {
        if(mode.kind !== "text" || mode.text?.id !== text.id){
            
            setMode(m => {
                return { kind: "text", text };
            });
        }
    }, []);

    const selectTextMode = React.useCallback(() => {
        setMode(m => {
            return { kind: "text", text: {text: 'label', x: 50, y: 50, rotationDegrees: 0, id: null} };
        });
    }, []);

    const remove = React.useCallback(
        () => onRemove(configuredModule.id),
        [floor, index]);

    const rotate = React.useCallback(
        () => onRotate(configuredModule.id),
        [floor, index]);

    const saveLabel = React.useCallback((text: Label | TextState, str: string, x: number, y: number, rotation: number) => {
        if(text.id === null){
            setMode({ kind: "text", text: null });
        }

        if (text.id) {
            onUpdateLabel(text.id, str, x, y, rotation);
        } else {
            onCreateLabel(id, str, x, y, rotation);
        }
    }, []);

    React.useEffect(() => {
        if (mode.kind === "options" && mode.hotspotId && typeof mode.optionId !== "undefined") {
            
            if (mode.optionId === null && !customizations.some(c => c.hotspotId === mode.hotspotId)) {
                return;
            }

            const hasCustomization = customizations.some(c =>
                c.hotspotId === mode.hotspotId &&
                c.optionId === mode.optionId);

            if (hasCustomization) {
                return;
            }

            onOptionSelected(configuredModule.id, mode.hotspotId, mode.optionId);
        }
    }, [customizations, mode]);

    React.useEffect(() => {
        const $ref = cmRef.current;

        if ($ref === null) {
            return;
        }

        const cursorClass = "cursor-text";

        if (mode.kind === "text") {
            $ref.classList.add(cursorClass);
        } else {
            $ref.classList.remove(cursorClass);
        }

        return () => {
            // Reset cursor when component is unmounted
            $ref.classList.remove(cursorClass);
        };
    }, [mode]);

    return (
        <Row className="align-items-stretch h-100 overflow-hidden">
            <Col className="h-100 position-relative overflow-auto">
                <ButtonToolbar
                    className="position-absolute top-0 end-0 me-3 d-flex justify-content-end gap-2 align-items-stretch"
                    style={{ zIndex: 1 }}
                >
                    <Button
                        active={mode.kind === "furniture"}
                        onClick={selectFurnitureMode}
                        variant="primary"
                        title="Indsæt inventar"
                    >
                        <IconLabel icon={MdOutlineChair} />
                    </Button>

                    <Button
                        onClick={selectTextMode}
                        variant="primary"
                        title="Indsæt / rediger tekst"
                    >
                        <IconLabel icon={BsType} />
                    </Button>

                    <div className="vr"></div>

                    <ShakingButton onClick={rotate}>
                        <Button variant="secondary" title="roter">
                            <IconLabel icon={BsArrowClockwise} />
                        </Button>
                    </ShakingButton>

                    <ShakingButton onClick={remove} title="Slet">
                        <Button variant="secondary">
                            <IconLabel icon={BsTrashFill} />
                        </Button>
                    </ShakingButton>
                </ButtonToolbar>

                <div className="d-flex flex-column h-100">
                    <p className="fs-5 text">{floorName} - {module.name}</p>
                    <p>
                        Tryk på <BsPencil /> for at tilpasse forskellige dele af modulet<br />
                        Tryk på <MdOutlineChair /> for at indsætte inventar, tryk igen for at forlade menu<br />
                        Tryk på <BsType /> for at indsætte tekst, tryk igen for at forlade menu<br />
                        <span className="text-danger">{recommendedStairs}</span>
                    </p>

                    <div className="flex-fill d-flex flex-column justify-content-stretch overflow-hidden" style={{minHeight: 0, padding: '9% 20%'}}>
                        <div className="mx-auto flex-grow-1 mw-100"
                            style={{aspectRatio: `${module.lengthMm} / ${module.widthMm}`, minHeight: 0}}
                            onDragOver={event => {
                                event.preventDefault();
                                event.dataTransfer.dropEffect = "copy";
                            }}
                            onDrop={dropFurniture}
                        >
                            <ConfiguredModuleView
                                configuredModule={configuredModule}
                                module={module}
                                onClick={click}
                                ref={cmRef}
                            >
                                <>
                                    {configuredModule.customizations.map(customization =>
                                    <>
                                        <CustomizationView key={customization.id}
                                            customization={customization}
                                            onClick={() => selectCustomization(customization.id)}
                                            scale={[1.0 / module.lengthMm, 1.0 / module.widthMm]}
                                            z={mode.kind === "customization" && mode.customizationId === customization.id ? 1 : 0}
                                        >
                                        </CustomizationView>
                                    
                                        {mode.kind === "customization" && mode.customizationId === customization.id && <CustomizationActions
                                                hotspot={hotspots.find(e => e.id == customization.hotspotId)}
                                                customization={customization}
                                                rotationDegrees={rotationDegrees}
                                                onFlipX={onFlipX}
                                                onFlipY={onFlipY}
                                                onRotate={onRotateCustomization}
                                        />}
                                    </>)}

                                    {mode.kind === "options" && hotspots.map(hotspot =>
                                    <Button
                                        key={hotspot.id}
                                        className={`position-absolute p-1 rounded-circle shadow ${mode.hotspotId === hotspot.id ? "active" : ""}`}
                                        onClick={() => selectHotspot(hotspot.id)}
                                        style={{ left: `${hotspot.x}%`, top: `${hotspot.y}%`, transform: `translate(-25%, -50%) rotate(-${rotationDegrees}deg)` }}
                                        variant={mode.hotspotId === hotspot.id ? "primary" : "secondary"}>
                                        <IconLabel icon={BsPencil} />
                                    </Button>)}

                                    {placedFurnitures.map(placedFurniture =>
                                    <PlacedFurnitureView
                                        key={placedFurniture.id}
                                        getBoundingClientRect={getBoundingClientRect}
                                        onClick={() => selectPlacedFurniture(placedFurniture.id)}
                                        onMove={(x, y) => onMoveFurniture(placedFurniture.id, x, y)}
                                        placedFurniture={placedFurniture}
                                        rotationDegrees={rotationDegrees}
                                        scale={[1.0 / module.lengthMm, 1.0 / module.widthMm]}
                                    >
                                        {mode.kind === "placedFurniture" && mode.placedFurnitureId === placedFurniture.id &&
                                        <PlacedFurnitureActions
                                            onRemove={onPlacedFurnitureRemoved}
                                            placedFurniture={placedFurniture}
                                            rotationDegrees={rotationDegrees}
                                            onSubmit={() => setMode({...defaultMode})}

                                        />}
                                    </PlacedFurnitureView>)}

                                    {labels.map(label => <TextInput
                                        {...label}
                                        autoFocus={true}
                                        key={label.id}
                                        canEdit={mode.kind === "text" && mode.text?.id === label.id}
                                        getBoundingClientRect={getBoundingClientRect}
                                        onSave={(str, x, y, rotation) => saveLabel(label, str, x, y, rotation)}
                                        onClick={() => selectLabel(label)}
                                        onSubmit={() => setMode({...defaultMode})}
                                        containerRotationDegrees={rotationDegrees}
                                        onRemove={() => saveLabel(label, '', label.x, label.y, label.rotationDegrees)}
                                    />)}

                                    {mode.kind === "text" && mode.text !== null  && mode.text.id === null && <TextInput
                                        {...mode.text}
                                        autoFocus={true}
                                        canEdit={true}
                                        getBoundingClientRect={getBoundingClientRect}
                                        onSave={(str, x, y, rotation) => saveLabel(mode.text!, str, x, y, rotation)}
                                        containerRotationDegrees={rotationDegrees}
                                        onClick={() => {}}
                                        onRemove={() => {}}
                                        onSubmit={() => {}}
                                    />}
                                </>
                            </ConfiguredModuleView>
                        </div>
                    </div>
                </div>
            </Col>

            {mode.kind === "furniture" &&
            <Col md={4} xl={3} xxl={2} className="h-100">
                <div className="border border-1 bg-light shadow-sm rounded d-flex flex-column gap-3 h-100 overflow-auto p-4">
                    {furnitures.map(furniture =>
                    <div key={furniture.id}
                        className={`bg-white border ${furniture.id === mode.furnitureId ? "border-primary" : ""} d-flex flex-column p-2 position-relative rounded`}
                        draggable={true}
                        onDragStart={event => {
                            const { dataTransfer } = event;
                            dataTransfer.dropEffect = "copy";
                            dataTransfer.setData("text/plain", JSON.stringify(furniture.id));

                            const image = document.createElement("img");
                            image.src = furniture.image;
                            image.style.width = `${furniture.widthMm}%`;
                            image.style.height = `${furniture.heightMm}%`;
                            dataTransfer.setDragImage(image, 0, 0);
                        }}
                    >
                        <div className="p-2" style={{ maxHeight: 200 }}>
                            <Image
                                alt={furniture.name}
                                className="d-block mx-auto"
                                src={furniture.image}
                                style={{ maxHeight: "100%", maxWidth: "100%" }}
                                title={furniture.name}
                            />
                        </div>

                        <p className="m-0 text-center user-select-none">
                            {furniture.name}
                        </p>
                    </div>)}
                </div>
            </Col>}

            {mode.kind === "options" && mode.hotspotId &&
            <Col md={4} lg={3} xl={2} className="h-100">
                <div className="border border-1 bg-light shadow-sm rounded d-flex flex-column gap-3 h-100 overflow-auto p-4">
                    {hotspotOptions.map(hotspotOption =>
                    <OptionView
                        key={hotspotOption.optionId}
                        hotspotOption={hotspotOption}
                        onSelect={selectOption}
                        selectedOptionId={mode.optionId}
                    />)}
                </div>
            </Col>}
        </Row>
    );
});

CustomizeModuleContainer.displayName = "CustomizeModuleContainer";

const OptionView: React.FC<{
    hotspotOption: HotspotOption;
    onSelect: (optionId: string) => void;
    selectedOptionId?: string | null;
}> = ({
    hotspotOption,
    onSelect,
    selectedOptionId,
}) => {
    const { options } = React.useContext(StaticDataContext);
    const option = options.find(o => o.id === hotspotOption.optionId)!;

    return (
        <div key={hotspotOption.optionId}
            className={`bg-white border ${hotspotOption.optionId === selectedOptionId ? "border-primary" : ""} d-flex flex-column p-2 position-relative rounded`}
            onClick={() => onSelect(hotspotOption.optionId)}
        >
            <div className="p-2">
                <Image
                    alt={option.name}
                    className="d-block mx-auto"
                    src={option.image}
                    style={{ maxHeight: 200, maxWidth: "100%" }}
                    title={option.name}
                />
            </div>

            <p className="m-0 text-center user-select-none">
                <ReactMarkdown children={option.name} remarkPlugins={[remarkGfm]} />
            </p>

            {hotspotOption.optionId === selectedOptionId &&
            <BsCheck className="position-absolute text-primary icon-check" />}
        </div>);
};

const CustomizationActions: React.FC<{
    customization: Customization,
    rotationDegrees: number,
    hotspot?: Hotspot,
    onFlipX: (customizationId: string) => void,
    onFlipY: (customizationId: string) => void,
    onRotate: (customizationId: string) => void,
}> = React.memo(({
    customization,
    rotationDegrees,
    hotspot,
    onFlipX,
    onFlipY,
    onRotate,
}) => {
    const { options, hotspotOptions } = React.useContext(StaticDataContext);

    const option = options.find(o =>
        o.id === customization.optionId)!;

    const hotspotOption = hotspotOptions.find(ho =>
        ho.hotspotId === customization.hotspotId &&
        ho.optionId === customization.optionId)!;

    const { canFlipX, canFlipY, canRotate } = React.useMemo(
        () => options.find(o => o.id === customization.optionId)!,
        [customization]);

    const flipX: React.MouseEventHandler = React.useCallback((event) => {
        event.stopPropagation();
        onFlipX(customization.id);
    }, [customization, onFlipX]);

    const flipY: React.MouseEventHandler = React.useCallback((event) => {
        event.stopPropagation();
        onFlipY(customization.id);
    }, [customization, onFlipY]);

    const rotate: React.MouseEventHandler = React.useCallback((event) => {
        event.stopPropagation();
        onRotate(customization.id);
    }, [customization, onRotate]);

    rotationDegrees += hotspotOption.rotationDegrees;

    return (
        <div
            className="d-flex gap-2 position-absolute"
            style={{ zIndex: 100, left: `${hotspot?.x ?? 50}%`, top: `${hotspot?.y ?? 50}%`, transform: `translate(-50%, -50%) rotate(-${rotationDegrees}deg)`}}
        >
            {canFlipX && (
            <ShakingButton onClick={flipX}>
                <Button className="p-1 rounded-circle">
                    <IconLabel icon={CgEditFlipH} />
                </Button>
            </ShakingButton>)}

            {canFlipY && (
            <ShakingButton onClick={flipY}>
                <Button className="p-1 rounded-circle">
                    <IconLabel icon={CgEditFlipV} />
                </Button>
            </ShakingButton>)}

            {canRotate && (
            <ShakingButton onClick={rotate}>
                <Button className="p-1 rounded-circle">
                    <IconLabel icon={BiRotateLeft} />
                </Button>
            </ShakingButton>)}
        </div>
    );
});

const PlacedFurnitureActions: React.FC<{
    onRemove: () => void;
    onSubmit: () => void;
    placedFurniture: PlacedFurniture,
    rotationDegrees: number,
}> = React.memo(({
    onRemove,
    onSubmit,
    placedFurniture,
    rotationDegrees,
}) => {
    const dispatch = useAppDispatch();

    const remove: React.MouseEventHandler = React.useCallback(async (event) => {
        event.stopPropagation();

        const action = actions.removeFurniture({
            placedFurnitureId: placedFurniture.id,
        });

        await dispatch(action).unwrap();

        onRemove();
    }, [dispatch, onRemove, placedFurniture]);

    const rotate: React.MouseEventHandler = React.useCallback(async (event) => {
        event.stopPropagation();

        const action = actions.rotateFurniture({
            placedFurnitureId: placedFurniture.id,
            rotationDegrees: (placedFurniture.rotationDegrees + 45.0) % 360.0,
        });

        await dispatch(action).unwrap();
    }, [dispatch, placedFurniture]);

    const down: React.PointerEventHandler = React.useCallback((event) => {
        event.stopPropagation();
    }, []);

    const up: React.PointerEventHandler = React.useCallback((event) => {
        event.stopPropagation();
    }, []);

    return (
        <div
            className="d-flex gap-2 position-absolute top-100 start-50"
            draggable={false}
            style={{ transform: `translate(-50%, 50%) rotate(-${rotationDegrees}deg)` }}
        >
            <ShakingButton onClick={rotate} onPointerUp={up} onPointerDown={down}>
                <Button className="p-1 rounded-circle">
                    <IconLabel icon={BsArrowClockwise} />
                </Button>
            </ShakingButton>

            <ShakingButton onClick={remove} onPointerUp={up} onPointerDown={down}>
                <Button className="p-1 rounded-circle">
                    <IconLabel icon={BsTrashFill} />
                </Button>
            </ShakingButton>

            <Button className="p-1 rounded-circle">
                <IconLabel icon={BsArrowsMove} />
            </Button>

            <ShakingButton onClick={onSubmit} onPointerUp={up} onPointerDown={down}>
                <Button className="p-1 rounded-circle">
                    <IconLabel icon={BsCheck2} />
                </Button>
            </ShakingButton>
        </div>
    );
});

interface TextInputProps {
    autoFocus?: boolean;
    canEdit: boolean;
    text: string;
    containerRotationDegrees: number;

    getBoundingClientRect?: () => DOMRect;
    onSave: (text: string, x: number, y: number, rotationDegrees: number) => void;
    onRemove: () => void;
    onSubmit: () => void;
    onClick: () => void;
}

const TextInput: React.FC<LabelProps & TextInputProps> = React.memo(({
    autoFocus,
    canEdit,
    rotationDegrees,
    containerRotationDegrees,
    text,
    x,
    y,

    getBoundingClientRect,
    onSave,
    onRemove,
    onSubmit,
    onClick,
}) => {
    const maxLength = 100;
    const ref = React.createRef<HTMLDivElement>();
    const btnRef = React.createRef<HTMLButtonElement>();
    const [position, setPosition] = React.useState<[number, number]>([x, y]);
    const [rotation, setRotation] = React.useState(rotationDegrees);

    // we can't use useState because of a known issue:
    // https://github.com/lovasoa/react-contenteditable/issues/161
    const str = React.useRef(text);

    const blur = React.useCallback(() => {
        if (text !== str.current) {
            onSave(str.current!, position[0], position[1], rotation);
        }
    }, [onSave, position, text]);

    const click: React.MouseEventHandler = React.useCallback((event) => {
        onClick();
        // prevent weird click-through bug
        event.stopPropagation();
    }, []);

    const disableNewLines: React.KeyboardEventHandler = React.useCallback((event) => {
        if (event.key.toLowerCase() === "enter") {
            event.preventDefault();
            ref.current?.blur();
        }
    }, [onSave, ref]);

    const clampLength: React.KeyboardEventHandler = React.useCallback((event) => {
        disableNewLines(event);

        const el = ref.current;

        if (!el) {
            return;
        }

        const textContent = event.currentTarget.textContent!;
        const rem = +maxLength - el.innerText.length ?? 0;

        if (rem <= 0) {
            const slicedText = textContent.slice(0, +maxLength);
            if(ref.current) {
                ref.current.innerText = slicedText;
            }

            // place caret at end
            el.focus();
            const range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            var sel = window.getSelection();
            sel?.removeAllRanges();
            sel?.addRange(range);

            str.current = slicedText;
        } else {
            str.current = textContent;
        }
    }, [ref]);

    const pasteAsPlainText: React.ClipboardEventHandler = React.useCallback((event) => {
        event.preventDefault();

        const text = event.clipboardData?.getData("text/plain");

        if (text) {
            str.current = `${str.current}${text}`.substring(0, maxLength);
            ref.current?.blur();
        }
    }, [ref]);

    const down: React.PointerEventHandler = React.useCallback((event) => {
        if (!event.isPrimary || !onSave || !btnRef.current) {
            return;
        }

        btnRef.current.setPointerCapture(event.pointerId);
    }, [onSave, btnRef]);

    const up: React.PointerEventHandler = React.useCallback((event) => {
        if (!event.isPrimary || !onSave || !btnRef.current) {
            return;
        }

        btnRef.current.releasePointerCapture(event.pointerId);

        if (position[0] !== x || position[1] !== y) {
            onSave(str.current!, position[0], position[1], rotation);
        }
    }, [onSave, btnRef, x, y]);

    const move: React.PointerEventHandler = React.useCallback((event) => {
        if (!event.isPrimary || !onSave || !getBoundingClientRect || !btnRef.current || typeof containerRotationDegrees === "undefined") {
            return;
        }

        if (btnRef.current.hasPointerCapture(event.pointerId)) {
            const rect = getBoundingClientRect();
            const sign = containerRotationDegrees == 0 ? 1 : -1;
            const dx = 100.0 * sign * event.movementX / rect.width;
            const dy = 100.0 * sign * event.movementY / rect.height;
            setPosition(([x, y]) => [x + dx, y + dy]);
        }
    }, [getBoundingClientRect, onSave, btnRef, containerRotationDegrees]);

    const rotate: React.MouseEventHandler = React.useCallback(async (event) => {
        event.stopPropagation();

        var newRotation = (rotation + 45.0) % 360.0;
        setRotation(newRotation);

        onSave(str.current!, position[0], position[1], newRotation);
    }, [rotation, position]);

    React.useEffect(() => {
        setPosition([x, y]);
    }, [x, y]);

    React.useEffect(() => {
        setRotation(rotationDegrees);
    }, [rotationDegrees]);

    React.useEffect(() => {
        if (autoFocus) {
            ref.current?.focus();
        }
    }, [autoFocus, ref]);

    return (
        <LabelView
            className={canEdit ? "" : "user-select-none"}
            rotationDegrees={containerRotationDegrees}
            x={position[0]}
            y={position[1]}
        >
            <ContentEditable
                disabled={!canEdit}
                html={str.current}
                innerRef={ref}
                onBlur={blur}
                onChange={() => { }}
                onClick={click}
                onKeyDown={disableNewLines}
                onKeyUp={clampLength}
                onPaste={pasteAsPlainText}
                style={{
                    transform: `rotate(-${rotation}deg)` 
                }}
            />
            
            {canEdit && 
            <div
                className="d-flex gap-2 position-absolute top-50 start-50"
                draggable={false}
                style={{ transform: `translate(-50%, 75%) rotate(-${containerRotationDegrees}deg)` }}
            >
                    <ShakingButton onClick={rotate}>
                        <Button className="p-1 rounded-circle">
                            <IconLabel icon={BsArrowClockwise} />
                        </Button>
                    </ShakingButton>

                    <ShakingButton onClick={onRemove}>
                        <Button className="p-1 rounded-circle">
                            <IconLabel icon={BsTrashFill} />
                        </Button>
                    </ShakingButton>

                    <Button className="p-1 rounded-circle"
                        ref={btnRef}
                        onPointerCancel={up}
                        onPointerUp={up}
                        onPointerDown={down}
                        onPointerMove={move}>
                        <IconLabel icon={BsArrowsMove} />
                    </Button>

                    <ShakingButton onClick={onSubmit}>
                        <Button className="p-1 rounded-circle">
                            <IconLabel icon={BsCheck2} />
                        </Button>
                    </ShakingButton>
            </div>
            }
        </LabelView>
    );
});

TextInput.displayName = "TextInput";

// https://stackoverflow.com/a/17411276/15504397
function rotateDeg(cx: number, cy: number, x: number, y: number, angle: number): [number, number] {
    const radians = (Math.PI / 180) * angle;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = (cos * (x - cx)) + (sin * (y - cy)) + cx;
    const ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return [nx * 100.0, ny * 100.0];
}
