import * as React from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import { actions } from "../configurator/slice";
import { StaticDataContext } from "../contexts";
import { useAppDispatch, useAppSelector } from "../store";

import { Systems } from "./Systems";
import { Templates } from "./Templates";

export const Wizard = () => {
    const StaticData = React.useContext(StaticDataContext);

    // State
    const [systemId, setSystem] = React.useState<string | undefined>();
    const [templateId, setTemplate] = React.useState<number | null | undefined>();
    const [name, setName] = React.useState<string>("");

    const templates = React.useMemo(
        () => StaticData.templates.filter(t => t.systemId === systemId),
        [systemId]);

    const canClose = useAppSelector(state => state.configurator.systemId !== null);
    const show = useAppSelector(state => state.configurator.showWizard);

    // Actions
    const dispatch = useAppDispatch();

    // Callbacks
    const hide = () => {
        if (show) {
            dispatch(actions.hideWizard());
        }
    };

    const exited = () => {
        setSystem(undefined);
        setTemplate(undefined);
        setName("");
    };

    // Effects
    const commit = () => {
        if (systemId && typeof templateId !== "undefined") {
            dispatch(actions.commit({ systemId, templateId, templateName: templates.find(e => e.id == templateId)?.name, name })).then(() => hide());
        }
    };

    // Computed values
    const step = Number(typeof systemId !== "undefined") + Number(typeof templateId !== "undefined");

    const title =
        step === 0 ? "Vælg et byggesystem (1/2)" :
        step === 1 ? "Start fra bunden eller vælg en skabelon (2/2)" :
        step === 2 ? "Navngiv projekt" :
        "";

    const child =
        step === 0 ? <Systems selectSystem={setSystem} systems={StaticData.systems} /> :
        step === 1 ? <Templates selectTemplate={setTemplate} templates={templates} /> :
        step === 2 ? <div>
            <Form.Control
                maxLength={100}
                name="Project"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                placeholder="Projekt navn"
                type="text" />
            <Button className="h-100" onClick={commit} variant="outline-primary">
                Opret
            </Button>
            </div> :
        null;

    return (
        <Modal backdrop={canClose ? true : "static"} contentClassName="h-100" keyboard={canClose} onExit={hide} onExited={exited} onHide={hide} scrollable={true} show={show} size="xl">
            <Modal.Header closeButton={canClose}>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {child}
            </Modal.Body>
        </Modal>
    );
};
