import * as React from "react";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { actions } from "../configurator/slice";
import { StaticDataContext } from "../contexts";
import { useAppDispatch, useAppSelector } from "../store";
import { Module } from "../types";

import "./Modules.css";

export const Modules = () => {
    const StaticData = React.useContext(StaticDataContext);

    const modules = useAppSelector(state =>
        StaticData.modules.filter(m => m.systemId === state.configurator.systemId));

    const [details, setDetails] = React.useState(false);

    return (
        <React.Fragment>
            <Form className="d-flex flex-column" style={{minHeight: 250}}>
                <div className="mb-3">
                    <Form.Check
                        checked={details}
                        id="details"
                        label="Vis detaljer"
                        onChange={(event) => setDetails(event.target.checked)}
                        type="checkbox" />
                </div>

                <ListGroup className="gap-3 pb-3 overflow-auto">
                    {modules.map(module => (
                    <ModuleView details={details} key={module.id} module={module} />))}
                </ListGroup>
            </Form>
        </React.Fragment>
    );
};

interface ModuleViewProps {
    details: boolean;
    module: Module;
}

const ModuleView: React.FC<ModuleViewProps> = ({
    details,
    module,
}) => {
    const dispatch = useAppDispatch();

    const selectModule = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        dispatch(actions.selectModule(module.id));
    };

    const selected = useAppSelector(state => module.id === state.configurator.selectedModuleId);

    return (
        <ListGroup.Item
            active={selected}
            as="a"
            className={`list-group-item list-group-item-action border ${selected ? "border-secondary shadow-sm " : ""}`}
            href="#"
            onClick={selectModule}
        >
            <div className="p-3">
                <Image alt="" className="figure-img" fluid src={module.thumbnail} />
            </div>

            <div>
                <small>
                    {selected ? (
                    <strong>{module.name}</strong>) :
                    module.name}
                </small>
            </div>

            {details && (
            <div>
                <small className="text-secondary"><ReactMarkdown children={module.description} remarkPlugins={[remarkGfm]} /></small>
            </div>)}
        </ListGroup.Item>
    );
};
