import * as React from "react";
import Image from "react-bootstrap/Image";

import { StaticDataContext } from "../contexts";
import { PlacedFurniture } from "../types";

interface Props {
    getBoundingClientRect?: () => DOMRect;
    onClick?: React.MouseEventHandler;
    onMove?: (x: number, y: number) => void;
    placedFurniture: PlacedFurniture;
    rect?: DOMRect;
    rotationDegrees?: number;
    scale: [number, number];
    children?: React.ReactNode;
}

export const PlacedFurnitureView: React.FC<Props> = ({
    children,
    getBoundingClientRect,
    onClick,
    onMove,
    placedFurniture,
    rotationDegrees,
    scale,
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { furnitures } = React.useContext(StaticDataContext);
    const furniture = furnitures.find(f => f.id === placedFurniture.furnitureId)!;

    const [position, setPosition] = React.useState<[number, number]>([placedFurniture.x, placedFurniture.y]);

    const down: React.PointerEventHandler = React.useCallback((event) => {
        if (!event.isPrimary || !onMove || !ref.current) {
            return;
        }

        ref.current.setPointerCapture(event.pointerId);
    }, [onMove]);

    const up: React.PointerEventHandler = React.useCallback((event) => {
        if (!event.isPrimary || !onMove || !ref.current) {
            return;
        }

        ref.current.releasePointerCapture(event.pointerId);

        if (position[0] !== placedFurniture.x || position[1] !== placedFurniture.y) {
            onMove(position[0], position[1]);
        }
        
        if (onClick) {
            onClick(event);
        }
    }, [onClick, onMove, position]);

    const move: React.PointerEventHandler = React.useCallback((event) => {
        if (!event.isPrimary || !onMove || !getBoundingClientRect || !ref.current || typeof rotationDegrees === "undefined") {
            return;
        }

        if (ref.current.hasPointerCapture(event.pointerId)) {
            const rect = getBoundingClientRect();
            const sign = rotationDegrees == 0 ? 1 : -1;
            const dx = 100.0 * sign * event.movementX / rect.width;
            const dy = 100.0 * sign * event.movementY / rect.height;
            setPosition(([x, y]) => [x + dx, y + dy]);
        }
    }, [getBoundingClientRect, onMove, rotationDegrees]);

    React.useEffect(() => {
        setPosition([placedFurniture.x, placedFurniture.y]);
    }, [placedFurniture.x, placedFurniture.y]);

    return (
        <div
            className="position-absolute user-select-none"
            style={{
                left: `${position[0]}%`,
                top: `${position[1]}%`,


                width: `${scale[0] * 100.0 * furniture.widthMm}%`,
                height: `${scale[1] * 100.0 * furniture.heightMm}%`,
            }}
        >
            <div className="position-relative">
                <div
                    className={onClick ? "cursor-pointer" : ""}
                    onPointerCancel={up}
                    onPointerUp={up}
                    onPointerDown={down}
                    onPointerMove={move}
                    ref={ref}
                >
                    <Image
                        alt={furniture.name}
                        className="pe-none"
                        draggable={false}
                        fluid
                        style={{
                            transform: `rotate(${placedFurniture.rotationDegrees}deg)`,
                        }}
                        src={furniture.image}
                    />


                    {children}
                </div>

            </div>
        </div>
    );
};

PlacedFurnitureView.displayName = "PlacedFurniture";
