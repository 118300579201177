import * as React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Ratio from "react-bootstrap/Ratio";
import Row from "react-bootstrap/Row";

import { Template } from "../types";

export const Templates: React.FC<{
    selectTemplate(templateId: number | null): void;
    templates: Template[];
}> = ({
    selectTemplate,
    templates,
}) => (
    <Row className="row-cols-3 g-4 p-5">
        <Col>
            <Card className="h-100">
                <Card.Body className="bg-light d-flex flex-column justify-content-center">
                    <div className="d-grid">
                        <button className="btn btn-outline-primary" onClick={() => selectTemplate(null)} type="button">
                            Start fra bunden
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </Col>

        {templates.map(template => (
        <Col key={template.id}>
            <Card className="h-100">
                <Card.Img as="div" variant="top">
                    <Ratio aspectRatio={ 800 / 1300 }>
                        <Image alt="" height="100%" src={template.image} width="100%" />
                    </Ratio>
                </Card.Img>
                <Card.Body className="bg-light d-flex flex-column">
                    <Card.Title>{template.name}</Card.Title>
                    <Card.Text className="flex-fill">{template.description}</Card.Text>
                    <div className="d-grid">
                        <Button onClick={() => selectTemplate(template.id)} variant="outline-primary">
                            Vælg
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </Col>))}
    </Row>
);
