import * as React from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";

export const PillButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
    <Button size="sm" variant="light" ref={ref} {...props} className={`rounded-pill px-3 ${props.className}`}>
        {props.children}
    </Button>
));

PillButton.displayName = "PillButton";
