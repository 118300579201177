import * as React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Ratio from "react-bootstrap/Ratio";
import Row from "react-bootstrap/Row";

import { System } from "../types";

export const Systems: React.FC<{
    selectSystem(systemId: string): void;
    systems: System[];
}> = ({
    selectSystem,
    systems,
}) => (
    <Container className="h-100">
        <Row className="align-items-stretch h-100 p-5">
            {systems.map(system => (
            <Col className="h-100" key={system.id}>
                <Card className="h-100">
                    <Card.Img className="flex-shrink-1" variant="top" src={system.image} style={{ minHeight: 100, objectFit: 'cover' }} />

                    <Card.Body className="bg-light d-flex flex-column overflow-auto" style={{ minHeight: 150, flexBasis: 100 }}>
                        <Card.Title>
                            {system.name}
                        </Card.Title>

                        <Card.Text className="" dangerouslySetInnerHTML={{ __html: system.description }} />
                    </Card.Body>

                    <Card.Footer>
                        <Button
                            className="w-100"
                            onClick={() => selectSystem(system.id)}
                            variant="outline-primary"
                        >
                            Vælg
                        </Button>
                    </Card.Footer>
                </Card>
            </Col>))}
        </Row>
    </Container>
);
