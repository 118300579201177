import * as React from "react";
import Image from "react-bootstrap/Image";

import { ConfiguredModule, Module } from "../types";

interface Props {
    configuredModule: ConfiguredModule;
    module: Module;
    onClick?: React.MouseEventHandler<Ref>;
}

type Ref = HTMLDivElement;

const ConfiguredModuleViewInner = React.forwardRef<Ref, React.PropsWithChildren<Props>>(({
    children,
    configuredModule,
    module,
    onClick,
}, ref) => (
    <div
        className="position-relative"
        onClick={onClick}
        ref={ref}
        style={{ zIndex: 10, transform: `rotate(${configuredModule.rotationDegrees}deg)` }}
    >
        <Image
            alt={module.name}
            className="user-select-none w-100"
            draggable={false}
            fluid
            src={module.image}
        />

        {children}
    </div>
));

export const ConfiguredModuleView = React.memo(ConfiguredModuleViewInner);

ConfiguredModuleView.displayName = "ConfiguredModuleView";
