import React from "react";
import { StaticData } from "./types";

export const StaticDataContext = React.createContext<Readonly<StaticData>>({
    furnitures: [],
    hotspots: [],
    hotspotOptions: [],
    modules: [],
    options: [],
    systems: [],
    templates: [],
});
