import * as React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { BsArrowClockwise, BsArrowRepeat, BsCheck, BsPencil, BsPlus, BsTrashFill, BsArrowUp, BsArrowDown, BsAlignStart, BsAlignCenter, BsAlignEnd } from "react-icons/bs";
import { IoCopyOutline } from "react-icons/io5";

import { ConfiguredModuleView } from "./ConfiguredModule";
import { Connectors } from "./Connector";
import { CustomizationView } from "./Customization";
import { CustomizeModuleContainer } from "./CustomizeModule";
import { LabelView } from "./Label";
import { PlacedFurnitureView } from "./PlacedFurniture";

import { IconLabel } from "../components/IconLabel";
import { PillButton } from "../components/PillButton";
import { ShakingButton } from "../components/Shake";

import { actions } from "./slice";

import { StaticDataContext } from "../contexts";
import { useAppDispatch, useAppSelector } from "../store";

import "./Configurator.css";
import FacadeContainer from "./Facade";
import { Justification, Module } from "../types";

const ConfiguratorContainer = () => {
    const show = useAppSelector(state => state.configurator.systemId !== null);

    if (show) {
        return <ConfiguratorView />;
    } else {
        return null;
    }
};

const ConfiguratorView = () => {
    const staticData = React.useContext(StaticDataContext);
    const configId = useAppSelector(state => state.configurator.configurationId);

    const initialNumFloors = useAppSelector(state => {
        const floors = state.configurator.configuredModules.map(cm => cm.floor);
        const maxFloor = Math.max(...floors);
        return maxFloor + 1;
    });

    React.useEffect(() => {
        setNumFloors(initialNumFloors);
    }, [configId])

    const system = useAppSelector(state => staticData.systems.find(s => s.id === state.configurator.systemId));
    const maxFloors = system?.name == "Pavilloner" ? 2 : 3;

    const [numFloors, setNumFloors] = React.useState(initialNumFloors + 1);

    const [activeFloor, setActiveFloor] = React.useState(0);

    const dispatch = useAppDispatch();

    const addFloor = (floor: number) => {
        setNumFloors(floor);
        setActiveFloor(floor-1);
        dispatch(actions.selectConfiguredModule(null));
    };

    const removeFloor = (floor: number) => dispatch(actions.removeFloor({ floor: floor }))
        .unwrap()
        .then(() => setActiveFloor(0))
        .then(() => setNumFloors(numFloors - 1));

    return (
        <Col as="main" className="h-100 overflow-auto">
            <Row className="overflow-hidden">
                <Col className="mx-auto" sm={12} lg={10} xl={8} xxl={6} style={{paddingBottom: '4rem'}}>
                    <FloorView active={activeFloor === 0} floor={0} floorName="Stueplan">
                        <PillButton disabled={numFloors > 1} onClick={() => addFloor(2)}>
                            <IconLabel icon={BsPlus}>
                                Tilføj etage
                            </IconLabel>
                        </PillButton>
                    </FloorView>
                </Col>

                {numFloors > 1 &&
                <Col className="mx-auto" sm={12} lg={10} xl={8} xxl={6} style={{paddingBottom: '4rem'}}>
                    <FloorView active={activeFloor === 1} floor={1} floorName="1. sal">
                        <>
                            {maxFloors > 2 &&
                                <PillButton disabled={numFloors > 2} onClick={() => addFloor(3)}>
                                    <IconLabel icon={BsPlus}>
                                        Tilføj etage
                                    </IconLabel>
                                </PillButton>
                            }
                            <ShakingButton onClick={() => removeFloor(1)}>
                                <PillButton>
                                    <IconLabel icon={BsTrashFill}>
                                        Fjern etage
                                    </IconLabel>
                                </PillButton>
                            </ShakingButton>
                        </>
                    </FloorView>
                    </Col>}

                {numFloors > 2 &&
                    <Col className="mx-auto" sm={12} lg={10} xl={8} xxl={6} style={{ paddingBottom: '4rem' }}>
                        <FloorView active={activeFloor === 2} floor={2} floorName="2. sal">
                            <ShakingButton onClick={() => removeFloor(2)}>
                                <PillButton>
                                    <IconLabel icon={BsTrashFill}>
                                        Fjern etage
                                </IconLabel>
                                </PillButton>
                            </ShakingButton>
                        </FloorView>
                    </Col>}
            </Row>
            <Row>
                <Col className="mx-auto">
                    <div className="mx-auto">
                        <FacadeContainer />
                    </div>
                </Col>
            </Row>
        </Col>
    );
};

const FloorView: React.FC<{ active: boolean, floor: number, floorName: string, children?: React.ReactNode }> = ({
    active,
    children,
    floor,
    floorName,
}) => {
    const staticData = React.useContext(StaticDataContext);

    const systemId = useAppSelector(state => state.configurator.systemId);
    
    const lengthwiseJustificationGroundFloor = useAppSelector(state => state.configurator.lengthwiseJustificationGroundFloor);
    const lengthwiseJustification1stFloor = useAppSelector(state => state.configurator.lengthwiseJustification1stFloor);
    const lengthwiseJustification2ndFloor = useAppSelector(state => state.configurator.lengthwiseJustification2ndFloor);
    const justifications = [lengthwiseJustificationGroundFloor, lengthwiseJustification1stFloor, lengthwiseJustification2ndFloor];

    const justification = justifications[floor];

    const maxLength = React.useMemo(
        () => Math.max(...staticData.modules.filter(m => m.systemId === systemId).map(m => m.lengthMm)),
        [staticData, systemId]);

    const selectedModuleId = useAppSelector(state => state.configurator.selectedModuleId);

    const hasSelectedModule = React.useMemo(
        () => staticData.modules.some(m => m.id === selectedModuleId),
        [selectedModuleId]);
        
    const selectedModule = React.useMemo(
        () => staticData.modules.find(m => m.id === selectedModuleId),
        [selectedModuleId]);

    const selectedConfiguredModuleId = useAppSelector(state =>
        state.configurator.selectedConfiguredModuleId);

    
    const modules = useAppSelector(state => state
        .configurator
        .configuredModules
        .filter(cm => cm.floor === floor));

    const configuredModules = modules.map((cm, index) => {
            const canReplaceModule = hasSelectedModule && selectedModule !== undefined && selectedModule.floors.includes(cm.floor);
            const module = staticData.modules.find(m => m.id === cm.moduleId)!;
            const length = 100.0 * module.lengthMm / maxLength;
            const maxWidth = `${length}%`;
            const width = maxWidth;
            const selected = selectedConfiguredModuleId === cm.id;
            const first = index == 0;
            const last = index == modules.length-1;

            return [length, module, (
                <React.Fragment key={`${cm.id}`}>
                    {hasSelectedModule &&
                    <InsertSelectedModuleView
                        key={`${cm.id}-insert`}
                        module={selectedModule}
                        floor={floor}
                        onClick={() => insertSelectedModule(cm.index)} />}

                    <div
                        key={`${cm.id}-module`}
                        className={`configured-module position-relative ${(cm.moduleId.includes('Spacer') ? '' : 'bg-white')} ${selected ? "bg-light border border-primary my-1 p-3 shadow-lg" : ""}`}
                        style={{ fontSize: "75%", maxWidth, width }}>
                            <ConfiguredModuleView
                                configuredModule={cm}
                                module={module}
                            >
                            <>
                                <div className="pe-none">
                                    {cm.customizations.map(customization =>
                                    <CustomizationView
                                        key={customization.id}
                                        customization={customization}
                                        scale={[1.0 / module.lengthMm, 1.0 / module.widthMm]}
                                    />)}
                                </div>

                                <div
                                    className="cursor-pointer position-absolute w-100 h-100 top-0 start-0"
                                    onClick={() => dispatch(actions.selectConfiguredModule(cm.id))}
                                />

                                {cm.placedFurnitures.map(placedFurniture =>
                                <PlacedFurnitureView
                                    key={placedFurniture.id}
                                    placedFurniture={placedFurniture}
                                    scale={[1.0 / module.lengthMm, 1.0 / module.widthMm]}
                                />)}

                                {cm.labels.map(label =>
                                <div key={label.id} className="pe-none">
                                    <LabelView rotationDegrees={cm.rotationDegrees + label.rotationDegrees} x={label.x} y={label.y}>
                                        <span className="user-select-none">{label.text}</span>
                                    </LabelView>
                                </div>)}
                            </>
                        </ConfiguredModuleView>

                        {selected && <>
                        <span className="d-block fw-light text-center m-0 mt-2 user-select-none">{module.name}</span>

                        <div className="gap-4 d-flex position-absolute top-50 start-50 translate-middle" style={{zIndex: 20}}>
                            <ShakingButton
                                className="shadow"
                                onClick={() => rotate(cm.id)}
                                size="lg"
                                title="Roter 180°"
                                variant="secondary">
                                <PillButton>
                                    <IconLabel icon={BsArrowClockwise} />
                                </PillButton>
                            </ShakingButton>

                            <PillButton
                                className="shadow"
                                onClick={() => setShow(cm.id)}
                                size="lg"
                                title="Tilpas modul"
                                variant="primary">
                                <IconLabel icon={BsPencil} />
                            </PillButton>

                            <ShakingButton
                                className="shadow"
                                onClick={() => duplicate(cm.id)}
                                size="lg"
                                title="Dupliker"
                                variant="secondary">
                                <PillButton>
                                    <IconLabel icon={IoCopyOutline} />
                                </PillButton>
                            </ShakingButton>

                            <ShakingButton
                                className="shadow"
                                onClick={() => remove(cm.id)}
                                size="lg"
                                title="Slet"
                                variant="secondary">
                                <PillButton>
                                    <IconLabel icon={BsTrashFill} />
                                </PillButton>
                            </ShakingButton>

                            {!first && <ShakingButton
                                className="shadow"
                                onClick={() => move(cm.id, -1)}
                                size="lg"
                                title="Flyt"
                                variant="secondary">
                                <PillButton>
                                    <IconLabel icon={BsArrowUp} />
                                </PillButton>
                            </ShakingButton>}

                            {!last && <ShakingButton
                                className="shadow"
                                onClick={() => move(cm.id, 1)}
                                size="lg"
                                title="Flyt"
                                variant="secondary">
                                <PillButton>
                                    <IconLabel icon={BsArrowDown} />
                                </PillButton>
                            </ShakingButton>}
                        </div>
                        </>}

                        {canReplaceModule &&
                        <div className="position-absolute top-50 start-50 translate-middle" style={{zIndex: 20}}>
                            <ShakingButton
                                className="shadow-sm"
                                onClick={() => dispatch(actions.replaceModule({ configuredModuleId: cm.id })).unwrap()}
                                variant="primary">
                                <PillButton>
                                    <IconLabel icon={BsArrowRepeat}>
                                        Erstat
                                    </IconLabel>
                                </PillButton>
                            </ShakingButton>
                        </div>}
                    </div>
                </React.Fragment>
            )] as [number, Module, JSX.Element];
        })
        .flatMap(([length, module, element], index, array) => {
            // Add connector between modules
            if (index === 0) {
                return element;
            }

            if(module.id.includes('Spacer') || array[index - 1][1].id.includes('Spacer')){
                return element;
            }

            // The width of the connector will be the length of the shortest module
            const shortest = Math.min(length, array[index - 1][0] || Infinity);

            return [
                <Connectors key={`${element.key}-connector`} width={shortest} />,
                element
            ];
        });

    // Size rulers
    const [showSize, setShowSize] = React.useState(false);

    // Find all of the used module IDs so we can calculate width and length
    const moduleIds = useAppSelector(state => state
        .configurator
        .configuredModules
        .filter(cm => cm.floor === floor)
        .map(cm => cm.moduleId));
        
    const system = useAppSelector(state => staticData.systems.find(s => s.id === state.configurator.systemId));

    let modalTitle = "Tilpas modul";
    if(system?.name == "Pavilloner"){
        modalTitle = "Tilpas Pavillon"
    }




    const hasConfiguredModules = React.useMemo(
        () => moduleIds.length > 0,
        [moduleIds]);

    // Find max used module length to adjust the width of the horizontal ruler
    const maxModuleLength = React.useMemo(
        () => Math.max(...staticData.modules
            .filter(m => m.systemId === systemId)
            .map(m => m.lengthMm)),
        [staticData, systemId]);

    // size[0] is the length of the longest module
    // size[1] is the sum of the module widths
    const size = React.useMemo(() =>
        moduleIds
            .flatMap(moduleId => staticData
                .modules
                .filter(m => m.id === moduleId)
                .map(m => [m.lengthMm, m.widthMm]))
            .reduce((acc, cur) => [Math.max(acc[0], cur[0]), acc[1] + cur[1]], [0, 0]) as [number, number],
        [moduleIds]);

    // Hide the rulers if there are no modules
    React.useEffect(
        () => setShowSize(sz => sz && hasConfiguredModules),
        [hasConfiguredModules])

    const [show, setShow] = React.useState<string | null>(null);
    const showConfiguredModule = useAppSelector(state =>
        state.configurator.configuredModules.find(cm => cm.id === show));

    const dispatch = useAppDispatch();

    const createLabel = React.useCallback(
        (configuredModuleId: string, text: string, x: number, y: number, rotationDegrees: number) => dispatch(actions.createLabel({ configuredModuleId, text, x, y, rotationDegrees })),
        [dispatch]);

    const flipX = React.useCallback(
        (customizationId: string) => dispatch(actions.flipX({ customizationId })).unwrap(),
        [dispatch]);

    const flipY = React.useCallback(
        (customizationId: string) => dispatch(actions.flipY({ customizationId })).unwrap(),
        [dispatch]);

    const rotateCustomization = React.useCallback(
        (customizationId: string) => dispatch(actions.rotate({ customizationId })).unwrap(),
        [dispatch]);

    const duplicate = React.useCallback(
        (configuredModuleId: string) => dispatch(actions.duplicateModule({ configuredModuleId })).unwrap(),
        [dispatch]);

    const move = React.useCallback(
        (configuredModuleId: string, distance: number) => dispatch(actions.moveModule({ configuredModuleId, distance })).unwrap(),
        [dispatch]);

    const justify = React.useCallback(
        (floor: number, justification: Justification) => dispatch(actions.changeLengthwiseJustification({ floor, justification })).unwrap(),
        [dispatch]);

    const hide = React.useCallback(
        () => setShow(null),
        []);

    const insertFurniture = React.useCallback(
        (configuredModuleId: string, furnitureId:string, x: number, y: number, rotation: number) => dispatch(actions.insertFurniture({ configuredModuleId, furnitureId, x, y, rotation })).unwrap(),
        [dispatch]);

    const insertSelectedModule = React.useCallback(
        (index: number) => dispatch(actions.insertSelectedModule({ floor, index })).unwrap(),
        [dispatch, floor]);

    const moveFurniture = React.useCallback(
        (placedFurnitureId: string, x: number, y: number) => dispatch(actions.moveFurniture({ placedFurnitureId, x, y })).unwrap(),
        [dispatch]);

    const remove = React.useCallback(
        (configuredModuleId: string) => dispatch(actions.removeModule({ configuredModuleId })).unwrap().then(hide),
        [dispatch, hide]);

    const rotate = React.useCallback(
        (configuredModuleId: string) => dispatch(actions.rotateModule({ configuredModuleId })).unwrap(),
        [dispatch]);

    const selectOption = React.useCallback(
        (configuredModuleId: string, hotspotId: string, optionId: string | null) => dispatch(actions.customizeModule({ configuredModuleId, hotspotId, optionId })).unwrap(),
        [dispatch]);

    const updateLabel = React.useCallback(
        (labelId: string, text: string, x: number, y: number, rotationDegrees: number) => dispatch(actions.updateLabel({ labelId, text, x, y, rotationDegrees })).unwrap(),
        [dispatch]);

    let alignment = 'align-items-start';
    if(justification == 'Center'){
        alignment = 'align-items-center';
    }
    if(justification == 'Right'){
        alignment = 'align-items-end';
    }

    return (
        <div className="pb-4 d-flex flex-column align-items-center h-100">
            <div className="w-100 bg-light d-flex flex-column align-items-center mb-5 position-relative pt-4" style={{zIndex:100}}>
                <div className="d-flex gap-3 mb-4">
                    {React.Children.only(children)}

                    <div className="vr"></div>

                    <Form.Check
                        checked={showSize}
                        className="align-self-center"
                        disabled={!hasConfiguredModules}
                        id={`showSize-${floor}`}
                        label="Vis størrelse"
                        onChange={(event) => setShowSize(event.target.checked)}
                        type="checkbox">
                    </Form.Check>

                    <div className="vr"></div>
                </div>

                <Badge
                    bg={active ? "primary" : "white"}
                    className="text-uppercase p-2 w-75"
                    pill
                >{floorName}</Badge>

                <div className="pt-2">
                    <Button size="sm" variant="outline-primary" active={justification == "Left"} onClick={() => justify(floor, "Left")}><BsAlignStart /></Button>
                    <Button size="sm" variant="outline-primary" active={justification == "Center"} onClick={() => justify(floor, "Center")}><BsAlignCenter /></Button>
                    <Button size="sm" variant="outline-primary" active={justification == "Right"} onClick={() => justify(floor, "Right")}><BsAlignEnd /></Button>
                </div>

            </div>

            <div className="position-relative w-75 mt-2">
                {hasConfiguredModules && showSize && (
                <div className={`d-flex flex-column ${alignment}`}>
                    <div
                        className="border-bottom border-secondary text-center position-absolute"
                        style={{ top: "-45px", width: `${100.0 * size[0] / maxModuleLength}%` }}
                    >
                        <span className="text-secondary">
                            {size[0]} mm
                        </span>
                    </div>
                </div>)}

                <div className={`d-flex flex-column ${alignment} mt-5 position-relative`}>
                    {configuredModules}

                    {hasConfiguredModules && showSize && (
                    <div
                        className="position-absolute top-0 text-center border-start border-secondary h-100"
                        style={{
                            transform: "rotate(180deg) translateX(75px)",
                            writingMode: "vertical-rl",
                            alignSelf: 'start'
                        }}
                    >
                        <span className="text-secondary">
                            {size[1]} mm
                        </span>
                    </div>)}
                </div>
            </div>

            {hasSelectedModule && configuredModules.length > 0 &&
            <InsertSelectedModuleView module={selectedModule} floor={floor} onClick={() => insertSelectedModule(configuredModules.length )} />}

            {configuredModules.length === 0 &&
            <div className="d-flex justify-content-center bg-white w-75" style={{ height: "200px" }}>
                {hasSelectedModule ? (
                <div className="align-self-center">
                    <InsertSelectedModuleView module={selectedModule} floor={floor} onClick={() => insertSelectedModule(configuredModules.length)} />
                </div>
                ) : (
                <span className="align-self-center text-center text-secondary">
                    Vælg et modul fra listen til venstre
                </span>)}
            </div>}

            <Modal
                contentClassName="w-100 h-100"
                dialogClassName="big-modal"
                onHide={hide}
                scrollable
                show={!!showConfiguredModule}
                size="xl">
                <Modal.Header>
                    <Modal.Title>
                        {modalTitle}
                    </Modal.Title>

                    <Button onClick={hide} variant="outline-primary">
                        <IconLabel iconClassName="icon-check " icon={BsCheck}>
                            Gem
                        </IconLabel>
                    </Button>
                </Modal.Header>

                <Modal.Body>
                    {showConfiguredModule && <CustomizeModuleContainer
                        configuredModule={showConfiguredModule}
                        floorName={floorName}
                        onCreateLabel={createLabel}
                        onFlipX={flipX}
                        onFlipY={flipY}
                        onRotateCustomization={rotateCustomization}
                        onInsertFurniture={insertFurniture}
                        onMoveFurniture={moveFurniture}
                        onOptionSelected={selectOption}
                        onRemove={remove}
                        onRotate={rotate}
                        onUpdateLabel={updateLabel}
                    />}
                </Modal.Body>
            </Modal>
        </div>
    );
};

const InsertSelectedModuleView: React.FC<{ onClick: () => void, module: Module | undefined, floor: number }> = ({
    onClick, module, floor
}) => {
    if(module != undefined && module.floors.includes(floor)){
        return (
        <ShakingButton
            className="align-self-center shadow-sm my-3"
            onClick={onClick}
            style={{ zIndex: 1 }}
            variant="primary">
            <PillButton>
                <IconLabel icon={BsPlus}>
                    Indsæt
                </IconLabel>
            </PillButton>
        </ShakingButton>
        );
    }
    return null;
};

export default ConfiguratorContainer;
