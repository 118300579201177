import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { StaticDataContext } from "../contexts";
import { useAppDispatch, useAppSelector } from "../store";
import { ConfiguredModule, Customization, Justification, Module } from "../types";
import Image from "react-bootstrap/Image";
import { useState } from "react";
import { BsArrowDown, BsArrowUp, BsArrowLeft, BsArrowRight, BsAlignStart, BsAlignCenter, BsAlignEnd } from 'react-icons/bs';
import { actions } from "./slice";

const TOP = 1;
const RIGHT = 2;
const BOTTOM = 4;
const LEFT = 8;

const FacadeContainer = () => {
    const show = useAppSelector(state => state.configurator.systemId !== null);

    if (show) {
        return <FacadeView />;
    } else {
        return null;
    }
};

const FacadeView = () => {
    const dispatch = useAppDispatch();
    
    const justification1stFloor = useAppSelector(state => state.configurator.justification1stFloor);
    const justification2ndFloor = useAppSelector(state => state.configurator.justification2ndFloor);
    
    const lengthwiseJustificationGroundFloor = useAppSelector(state => state.configurator.lengthwiseJustificationGroundFloor);
    const lengthwiseJustification1stFloor = useAppSelector(state => state.configurator.lengthwiseJustification1stFloor);
    const lengthwiseJustification2ndFloor = useAppSelector(state => state.configurator.lengthwiseJustification2ndFloor);

    const justify = React.useCallback(
        (floor: number, justification: Justification) => dispatch(actions.changeJustification({ floor, justification })).unwrap(),
        [dispatch]);
        
    const justifyLengthwise = React.useCallback(
        (floor: number, justification: Justification) => dispatch(actions.changeLengthwiseJustification({ floor, justification })).unwrap(),
        [dispatch]);

    const numFloors = useAppSelector(state => {
        const floors = state.configurator.configuredModules.map(cm => cm.floor);
        const maxFloor = Math.max(...floors);
        return maxFloor + 1;
    });

    const [side, setSide] = useState(TOP);

    return (
        <>
            <Col className="mx-auto pt-2">
                <Row className="justify-content-md-center">
                    <Col md="4">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Langside</th>
                                    <th>Gavl</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(numFloors > 2) &&
                                    <tr>
                                        <td>2. sal</td>
                                        <td>
                                            <Button size="sm" variant="outline-primary" active={lengthwiseJustification2ndFloor == "Left"} onClick={() => justifyLengthwise(2, "Left")} disabled={side == LEFT || side == RIGHT}><BsAlignStart /></Button>
                                            <Button size="sm" variant="outline-primary" active={lengthwiseJustification2ndFloor == "Center"} onClick={() => justifyLengthwise(2, "Center")} disabled={side == LEFT || side == RIGHT}><BsAlignCenter /></Button>
                                            <Button size="sm" variant="outline-primary" active={lengthwiseJustification2ndFloor == "Right"} onClick={() => justifyLengthwise(2, "Right")} disabled={side == LEFT || side == RIGHT}><BsAlignEnd /></Button>
                                        </td>
                                        <td>
                                            <Button size="sm" variant="outline-primary" active={justification2ndFloor == "Left"} onClick={() => justify(2, "Left")} disabled={side == TOP || side == BOTTOM}><BsAlignStart /></Button>
                                            <Button size="sm" variant="outline-primary" active={justification2ndFloor == "Center"} onClick={() => justify(2, "Center")} disabled={side == TOP || side == BOTTOM}><BsAlignCenter /></Button>
                                            <Button size="sm" variant="outline-primary" active={justification2ndFloor == "Right"} onClick={() => justify(2, "Right")} disabled={side == TOP || side == BOTTOM}><BsAlignEnd /></Button>
                                        </td>
                                    </tr>
                                }
                                {(numFloors > 1) &&
                                    <tr>
                                        <td>1. sal</td>
                                        <td>
                                            <Button size="sm" variant="outline-primary" active={lengthwiseJustification1stFloor == "Left"} onClick={() => justifyLengthwise(1, "Left")} disabled={side == LEFT || side == RIGHT}><BsAlignStart /></Button>
                                            <Button size="sm" variant="outline-primary" active={lengthwiseJustification1stFloor == "Center"} onClick={() => justifyLengthwise(1, "Center")} disabled={side == LEFT || side == RIGHT}><BsAlignCenter /></Button>
                                            <Button size="sm" variant="outline-primary" active={lengthwiseJustification1stFloor == "Right"} onClick={() => justifyLengthwise(1, "Right")} disabled={side == LEFT || side == RIGHT}><BsAlignEnd /></Button>
                                        </td>
                                        <td>
                                            <Button size="sm" variant="outline-primary" active={justification1stFloor == "Left"} onClick={() => justify(1, "Left")} disabled={side == TOP || side == BOTTOM}><BsAlignStart /></Button>
                                            <Button size="sm" variant="outline-primary" active={justification1stFloor == "Center"} onClick={() => justify(1, "Center")} disabled={side == TOP || side == BOTTOM}><BsAlignCenter /></Button>
                                            <Button size="sm" variant="outline-primary" active={justification1stFloor == "Right"} onClick={() => justify(1, "Right")} disabled={side == TOP || side == BOTTOM}><BsAlignEnd /></Button>
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <td>Stueplan</td>
                                    <td>
                                        <Button size="sm" variant="outline-primary" active={lengthwiseJustificationGroundFloor == "Left"} onClick={() => justifyLengthwise(0, "Left")} disabled={side == LEFT || side == RIGHT}><BsAlignStart /></Button>
                                        <Button size="sm" variant="outline-primary" active={lengthwiseJustificationGroundFloor == "Center"} onClick={() => justifyLengthwise(0, "Center")} disabled={side == LEFT || side == RIGHT}><BsAlignCenter /></Button>
                                        <Button size="sm" variant="outline-primary" active={lengthwiseJustificationGroundFloor == "Right"} onClick={() => justifyLengthwise(0, "Right")} disabled={side == LEFT || side == RIGHT}><BsAlignEnd /></Button>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>

                <Row>
                    <div className="text-center pt-4 mb-4 mt-4">
                        <Button size="sm" variant="outline-primary" active={side == TOP} onClick={() => setSide(TOP)}><BsArrowDown /></Button>
                        <br />
                        <Button size="sm" variant="outline-primary" active={side == LEFT} onClick={() => setSide(LEFT)}><BsArrowRight /></Button>
                        <span style={{display:'inline-block', width:34}} />
                        <Button size="sm" variant="outline-primary" active={side == RIGHT} onClick={() => setSide(RIGHT)}><BsArrowLeft /></Button>
                        <br />
                        <Button size="sm" variant="outline-primary" active={side == BOTTOM} onClick={() => setSide(BOTTOM)}><BsArrowUp /></Button>
                    </div>
                </Row>
                <Row className="mt-4 mx-auto">
                    {numFloors > 2 &&
                    <Col className="mx-auto" style={{zIndex:20}}>
                        <FloorView floor={2} side={side} />
                    </Col>}
                </Row>
                <Row className="mx-auto">
                    {numFloors > 1 &&
                    <Col className="mx-auto" style={{zIndex:10}}>
                        <FloorView floor={1} side={side} />
                    </Col>}
                </Row>
                <Row className="mx-auto">
                    <Col className="mx-auto">
                        <FloorView floor={0} side={side} />
                    </Col>
                </Row>
            </Col>
            <Col>
                <Row>
                    <span className="border border-secondary border-3 mb-4"></span>
                </Row>
            </Col>
        </>
    );
};

const FloorView: React.FC<{ floor: number, side: number }> = ({
    floor,
    side,
}) => {
    const staticData = React.useContext(StaticDataContext);
    const systemId = useAppSelector(state => state.configurator.systemId);
    const justification1stFloor = useAppSelector(state => state.configurator.justification1stFloor);
    const justification2ndFloor = useAppSelector(state => state.configurator.justification2ndFloor);

    const lengthwiseJustificationGroundFloor = useAppSelector(state => state.configurator.lengthwiseJustificationGroundFloor);
    const lengthwiseJustification1stFloor = useAppSelector(state => state.configurator.lengthwiseJustification1stFloor);
    const lengthwiseJustification2ndFloor = useAppSelector(state => state.configurator.lengthwiseJustification2ndFloor);

    const lengthWiseJustifications = [lengthwiseJustificationGroundFloor, lengthwiseJustification1stFloor, lengthwiseJustification2ndFloor];
    

    let configuredModules = useAppSelector(state => state
        .configurator
        .configuredModules
        .map(cm => {
            const module = staticData.modules.find(m => m.id === cm.moduleId)!;

            return {
                configuredModule: cm,
                module: module
            }
        }));

    const widths = ([0,1,2].map(floor => configuredModules.filter(cm => cm.configuredModule.floor === floor).reduce((sum, current) => sum + current.module.widthMm, 0)));
    const maxWidth = Math.max(...widths);

    const lengths = ([0,1,2].map(floor => Math.max(0, ...configuredModules.filter(cm => cm.configuredModule.floor === floor).map(c => c.module.lengthMm))));
    const maxLength = Math.max(...lengths);

    configuredModules = configuredModules.filter(m => m.configuredModule.floor == floor);

    const width = widths[floor];
    const length = lengths[floor];
    const height = Math.max(...configuredModules.map(e => e.module.height1stFloorMm));
    
    const actualHeight = Math.max(...configuredModules.map(e => floor == 0 ? e.module.heightGroundFloorMm : e.module.height1stFloorMm));


    if(configuredModules.length == 0){
        return null;
    }

    let scale = 0.75 * 0.5;
    let maxSize = (side == RIGHT || side == LEFT) ? maxWidth : maxLength;
    let size = (side == RIGHT || side == LEFT) ? width : length;

    if(side == RIGHT || side == LEFT){
        scale = maxWidth / maxLength;
        scale *= 0.75 * 0.5;
        scale = Math.min(scale, 0.8);
    }
    
    if(side == RIGHT || side == TOP){
        configuredModules.reverse();
    }


    const offsets = [0, 0, 0];


    if(justification1stFloor == "Left"){
        if(side == RIGHT) {
            offsets[1] = ((widths[0]-widths[1])*100)/maxSize;
        }
    }else if(justification1stFloor == "Right"){
        if(side == LEFT) {
            offsets[1] = ((widths[0]-widths[1])*100)/maxSize;
        }
    }else if(justification1stFloor == "Center"){
        if(side == LEFT || side == RIGHT) {
            offsets[1] = ((widths[0]-widths[1])*50)/maxSize;
        }
    }
    
    if(lengthWiseJustifications[1] == "Left"){
        if(side == TOP) {
            offsets[1] = ((lengths[0]-lengths[1])*100)/maxSize;
        }
    }else if(lengthWiseJustifications[1] == "Right"){
        if(side == BOTTOM) {
            offsets[1] = ((lengths[0]-lengths[1])*100)/maxSize;
        }
    }else if(lengthWiseJustifications[1] == "Center"){
        if(side == TOP || side == BOTTOM) {
            offsets[1] = ((lengths[0]-lengths[1])*50)/maxSize;
        }
    }


    offsets[2] = offsets[1];

    if(justification2ndFloor == "Left"){
        if(side == RIGHT) {
            offsets[2] = offsets[1] + ((widths[1]-widths[2])*100)/maxSize;
        }
    }else if(justification2ndFloor == "Right"){
        if(side == LEFT) {
            offsets[2] = offsets[1] + ((widths[1]-widths[2])*100)/maxSize;
        }
    }else if(justification2ndFloor == "Center"){
        if(side == LEFT || side == RIGHT) {
            offsets[2] = offsets[1] + ((widths[1]-widths[2])*50)/maxSize;
        }
    }
    
    if(lengthWiseJustifications[2] == "Left"){
        if(side == TOP) {
            offsets[2] = offsets[1] + ((lengths[1]-lengths[2])*100)/maxSize;
        }
    }else if(lengthWiseJustifications[2] == "Right"){
        if(side == BOTTOM) {
            offsets[2] = offsets[1] + ((lengths[1]-lengths[2])*100)/maxSize;
        }
    }else if(lengthWiseJustifications[2] == "Center"){
        if(side == TOP || side == BOTTOM) {
            offsets[2] = offsets[1] + ((lengths[1]-lengths[2])*50)/maxSize;
        }
    }

    let offset = offsets[floor] - Math.min(...offsets);

    const placeholder = <div style={{width:`${offset}%`}} />

    let bottom:JSX.Element|null = null;

    if(height == 3220){
        let size = side == TOP || side == BOTTOM ? length : width;


        let bottomWidth = `${100 - (((maxSize-size)*100)/maxSize)}%`;
        let padding = ((30)/maxSize)*100;

        if(floor == 0){
            let aspect = `${size}/230`;
            bottom = <div className="position-relative" style={{width: bottomWidth, aspectRatio: aspect}}>
                        <img className="h-100 position-absolute" style={{width:`${100 - padding * 2}%`, marginLeft: `${padding}%`}} src='img/pavillions/facades/Sokkel afdækning - stretches.svg' />
                    </div>;
        }else{
            let aspect = `${size}/100`;
            bottom = <div className="position-relative" style={{width: bottomWidth, aspectRatio: aspect}}>
                        <img className="w-100 h-100 position-absolute" src='img/pavillions/facades/samleskinne ved 1.sal - stretches.svg' />
                     </div>;
        }
    }

    return (<>
        <div className="d-flex position-relative mx-auto align-items-end" style={{width: `${scale * 100}%`}}>
            {(side == TOP || side == BOTTOM) && <div style={{width: `${(size*100)/maxSize}%`, aspectRatio: `${size}/${actualHeight}`}}></div>}
            {configuredModules.map((cm, i) => <ModuleView offset={offset} side={side} z={i} configuredModule={cm.configuredModule} module={cm.module} floorLength={size} totalLength={maxSize} key={`${cm.configuredModule.id}`} justification={lengthWiseJustifications[floor]}/>)}
        </div>
        <div className="d-flex position-relative mx-auto" style={{width: `${scale * 100}%`, zIndex:-1}}>
            {placeholder}
            {bottom}
        </div>
    </>);
};

const ModuleView: React.FC<{configuredModule: ConfiguredModule, module: Module, totalLength: number, floorLength: number, offset: number, side: number, z: number, justification:Justification}> = ({
    configuredModule,
    module,
    totalLength,
    floorLength,
    offset,
    side,
    z,
    justification
}) => {
    const isSide = (side == TOP || side == BOTTOM)
    const moduleWidth = isSide ? module.lengthMm : module.widthMm;
    const width = `${(moduleWidth*100)/totalLength}%`;
    const height = configuredModule.floor == 0 ? module.heightGroundFloorMm : module.height1stFloorMm;
    
    let rotatedSide = side;
    if(configuredModule.rotationDegrees == 180) {
        rotatedSide = rotatedSide >> 1 || 8;  
        rotatedSide = rotatedSide >> 1 || 8;
    }

    let img = module.frontImage1stFloor;
    if(rotatedSide == TOP || rotatedSide == BOTTOM){
        if(configuredModule.floor == 0){
            img = module.frontImageGroundFloor;
        }else{
            img = module.frontImage1stFloor;
        }
    }else if(rotatedSide == LEFT){
        if(configuredModule.floor == 0){
            img = module.endImageLeftGroundFloor;
        }else{
            img = module.endImageLeft1stFloor;
        }
    }else{
        if(configuredModule.floor == 0){
            img = module.endImageRightGroundFloor;
        }else{
            img = module.endImageRight1stFloor;
        }
    }

    if(side == BOTTOM){
        if(justification == 'Center'){
            offset += ((floorLength-moduleWidth)/totalLength)*50;
        }
        if(justification == 'Right'){
            offset += ((floorLength-moduleWidth)/totalLength)*100;
        }
    }

    if(side == TOP){
        if(justification == 'Center'){
            offset += ((floorLength-moduleWidth)/totalLength)*50;
        }
        if(justification == 'Left'){
            offset += ((floorLength-moduleWidth)/totalLength)*100;
        }
    }

    let customizations = configuredModule.customizations.map(e => <CustomizationView moduleHeight={height} floor={configuredModule.floor} side={rotatedSide} key={e.id} customization={e} scale={[1.0 / moduleWidth, 1.0 / height]} />);
    customizations.reverse();

    

    return (<div style={{width:width, overflow: 'visible', left:`${offset}%`, position: isSide ? 'absolute' : 'relative', zIndex: isSide ? z : 'auto'}}><Image
            alt={module.name}
            className="user-select-none w-100"
            draggable={false}
            fluid
            src={img} />
            {customizations}
        </div>);

};

const CustomizationView: React.FC<{side: number; floor:number; moduleHeight:number; customization: Customization; scale: [number, number];}> = ({
    side,
    floor,
    customization,
    scale,
    moduleHeight,
}) => {

    const { hotspotOptions, options, hotspots } = React.useContext(StaticDataContext);


    const option = options.find(o =>
        o.id === customization.optionId)!;

    const hotspotOption = hotspotOptions.find(ho =>
        ho.hotspotId === customization.hotspotId &&
        ho.optionId === customization.optionId)!;

    const hotspot = hotspots.find(o =>
        o.id === hotspotOption.hotspotId)!;

    let prevSide = side >> 1 || 8;  
    let nextSide = (side<<1) % 16 || 1;

    let translationX = 0;
    let translationY = 0;
    let width = 0;
    let height = 0;
    let image = null as string | null;
    let flip = false;

    if(hotspot.side == side && option.frontImage != null){
        translationX = hotspotOption.frontTranslationX;
        translationY = hotspotOption.frontTranslationY;
        width = option.frontWidthMm;
        height = option.frontHeightMm;
        image = option.frontImage;
    }

    if(hotspot.side == prevSide && option.sideImage != null){
        translationX = hotspotOption.sideTranslationX;
        translationY = hotspotOption.sideTranslationY;
        width = option.sideWidthMm;
        height = option.sideHeightMm;
        image = option.sideImage;
    }

    if(hotspot.side == nextSide && option.sideImage != null){
        translationX = 100 - hotspotOption.sideTranslationX;
        translationY = hotspotOption.sideTranslationY;
        width = option.sideWidthMm;
        height = option.sideHeightMm;
        image = option.sideImage;
        flip = true;
    }

    let yMultiplier = (floor == 0 && moduleHeight == 3190) ? (2990/3190) : 1;

    if(image == null){
        return null;
    }

    let scaleX = customization.flipX ? -1 : 1;
    let transformOriginX = option.frontTransformOriginX ?? option.transformOriginX ?? 50;

    if(hotspot.side != side){
        scaleX = 1;
    }
    
    if(hotspot.side == side && scaleX == -1 && option.flippedFrontImage != null){
        image = option.flippedFrontImage;
        scaleX = 1;
    }

    return (
        <div
            className="position-absolute pe-none"
            style={{
                left: `${translationX}%`,
                top: `${translationY * yMultiplier}%`,

                width: `${scale[0] * 100.0 * width}%`,
                height: `${scale[1] * 100.0 * height}%`,
                zIndex: 2,
                transformOrigin: `0 0`,
                transform: `scale(${flip ? -1 : 1}, 1)`,
            }}
        >
            <div
                className={`h-100 w-100 position-relative`}
            >
                <div
                    className="h-100 w-100"
                    style={{
                        transform: `scale(${scaleX}, 1)`,
                        transformOrigin: `${transformOriginX}% 0%`,
                        transformStyle: "preserve-3d",
                    }}
                >
                    <Image
                        alt={option.name}
                        className="h-100 w-100 position-absolute top-0 bottom-0 start-0 end-0 user-select-none"
                        draggable={false}
                        src={image}
                    />
                </div>
            </div>

        </div>
    );
};

export default FacadeContainer;