import * as React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BsDownload, BsFillEnvelopeFill, BsQuestionCircle, BsLock, BsUnlock, BsStickiesFill, BsPencil } from "react-icons/bs";

import { IconLabel } from "../components/IconLabel";
import { DownloadPdf } from "../downloadPdf/DownloadPdf";
import { Help } from "../help/Help";
import { RequestQuote } from "../requestQuote/RequestQuote";
import { useAppSelector, useAppDispatch } from "../store";
import { Login } from "../login/Login";
import { Configurations } from "../login/Configurations";
import { actions } from "../configurator/slice";

import Logo from "./logo_transparent.png";
import Logo2x from "./logo_transparent@2x.png";
import { Form, Modal } from "react-bootstrap";

export const NavMenu = () => {
    const configurationId = useAppSelector(state => state.configurator.configurationId ?? "");
    const configurationName = useAppSelector(state => state.configurator.configurationName ?? "");
    const loggedIn = useAppSelector(state => state.configurator.loggedIn);
    const [showHelp, setShowHelp] = React.useState(false);
    const [showRequestQuote, setShowRequestQuote] = React.useState(false);
    const [showLogin, setShowLogin] = React.useState(false);
    const [showProjects, setShowProjects] = React.useState(false);
    const [showLogout, setShowLogout] = React.useState(false);
    const [name, setName] = React.useState("");
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        setName(configurationName);
    }, [configurationName]);

    React.useEffect(() => {
        const to = setTimeout(() => {
            dispatch(actions.changeName({name}));
        }, 500)
    
        return () => clearTimeout(to)
    }, [name]);

    return (
        <>
            <Navbar bg="white" className="border-bottom navbar shadow-sm" expand="sm" fixed="top">
                <Container fluid>
                    <Navbar.Brand as="span">
                        <Image alt="Logo" src={Logo} srcSet={`${Logo} 1x, ${Logo2x} 2x`} width={69} height={31} />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav" className="d-sm-inline-flex flex-sm-row-reverse">
                        <Nav className="flex-grow gap-2">

                            <span className="navbar-text">
                                Projekt:
                            </span>

                            <Form className="d-flex">
                                <Form.Control
                                    maxLength={100}
                                    name="Project"
                                    value={name}
                                    onChange={(e) => setName(e.currentTarget.value)}
                                    placeholder="Projekt navn"
                                    type="text" />
                            </Form>

                            <Nav.Item hidden>
                                <Button className="h-100"  onClick={() => setShowHelp(true)} variant="outline-primary">
                                    <IconLabel icon={BsQuestionCircle} />
                                </Button>
                            </Nav.Item>

                            <Nav.Item>
                                <DownloadPdf className="h-100" configurationId={configurationId} variant="outline-primary">
                                    <IconLabel icon={BsDownload}>
                                        Download PDF
                                    </IconLabel>
                                </DownloadPdf>
                            </Nav.Item>

                            <Nav.Item>
                                <Button className="h-100" disabled={!configurationId} onClick={() => setShowRequestQuote(true)} variant="outline-primary">
                                    <IconLabel icon={BsFillEnvelopeFill}>
                                        Anmod om tilbud
                                    </IconLabel>
                                </Button>
                            </Nav.Item>

                            {loggedIn ? (
                                <>
                                    <Nav.Item>
                                        <Button className="h-100" onClick={() => setShowProjects(true)} variant="outline-primary">
                                            <IconLabel icon={BsStickiesFill}>
                                                Projekter
                                            </IconLabel>
                                        </Button>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Button className="h-100" onClick={() => setShowLogout(true)} variant="outline-primary">
                                            <IconLabel icon={BsUnlock}>
                                                Log ud
                                            </IconLabel>
                                        </Button>
                                    </Nav.Item>
                                </>
                                ) : (
                                <Nav.Item>
                                    <Button className="h-100" onClick={() => setShowLogin(true)} variant="outline-primary">
                                        <IconLabel icon={BsLock}>
                                            Login
                                        </IconLabel>
                                    </Button>
                                </Nav.Item>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Help onHide={() => setShowHelp(false)} show={showHelp} />
            <RequestQuote configurationId={configurationId} onHide={() => setShowRequestQuote(false)} show={showRequestQuote} />
            <Login onHide={() => setShowLogin(false)} show={showLogin} />
            <Configurations onHide={() => setShowProjects(false)} show={showProjects} />

            
            <Modal backdrop={true} onExit={() => setShowLogout(false)} onHide={() => setShowLogout(false)} show={showLogout} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Log ud
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    Er du sikker?<br />
                    <Button onClick={() => {dispatch(actions.logout()); setShowLogout(false)}}>Log ud</Button>
                </Modal.Body>
            </Modal>
        </>
    );
};
