import * as React from "react";
import Image from "react-bootstrap/Image";

import { StaticDataContext } from "../contexts";
import { Customization } from "../types";

interface Props {
    customization: Customization;
    onClick?: React.MouseEventHandler;
    scale: [number, number];
    z?: number;
    children?: React.ReactNode;
}

export const CustomizationView: React.FC<Props> = ({
    children,
    customization,
    onClick,
    scale,
    z,
}) => {
    const { hotspotOptions, options } = React.useContext(StaticDataContext);

    const option = options.find(o =>
        o.id === customization.optionId)!;

    const hotspotOption = hotspotOptions.find(ho =>
        ho.hotspotId === customization.hotspotId &&
        ho.optionId === customization.optionId)!;

    const canClick = React.useMemo(
        () => onClick && (option.canFlipX || option.canFlipY || option.canRotate),
        [onClick, option]);

    const click: React.MouseEventHandler = React.useCallback((event) => {
        if (canClick) {
            onClick!.call(this, event);
        }
    }, [canClick]);

    if(option.image == null || option.image == ""){
        return null;
    }

    return (
        <div
            className="position-absolute"
            style={{
                left: `${hotspotOption.translationX}%`,
                top: `${hotspotOption.translationY}%`,

                transform: `rotate(${hotspotOption.rotationDegrees}deg)`,

                width: `${scale[0] * 100.0 * option.widthMm}%`,
                height: `${scale[1] * 100.0 * option.heightMm}%`,
                zIndex: z,
            }}
        >
            <div
                className={`h-100 w-100 position-relative ${canClick ? "cursor-pointer" : ""}`}
                onClick={click}
            >
                <div
                    className="h-100 w-100"
                    style={{
                        transform: `scale(${customization.flipX ? -1 : 1}, ${customization.flipY ? -1 : 1})`,
                        transformOrigin: `${option.transformOriginX ?? 50}% ${option.transformOriginY ?? 50.0}%`,
                        transformStyle: "preserve-3d",
                    }}
                >
                    <Image
                        alt={option.name}
                        className="h-100 w-100 position-absolute top-0 bottom-0 start-0 end-0 user-select-none"
                        draggable={false}
                        src={option.image}
                    />
                </div>

                {children}
            </div>

        </div>
    );
};

CustomizationView.displayName = "Customization";
