import * as React from "react";

export const PrivacyPolicy: React.FC<{children?: React.ReactNode}> = ({
    children,
}) => (
    <a className="link-primary" href="https://www.cp.dk/persondata-politik" target="_blank">
        {children}
    </a>
);

export const TermsAndConditions: React.FC<{children?: React.ReactNode}> = ({
    children,
}) => (
    <a className="link-primary" href="https://www.cp.dk/generelle-betingelser" target="_blank">
        {children}
    </a>
);
