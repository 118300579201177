import * as React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { BsLock } from "react-icons/bs";
import ReactGA from 'react-ga4';

import { IconLabel } from "../components/IconLabel";

export const Login: React.FC<{
    onHide: () => void;
    show: boolean;
}> = ({
    onHide,
    show,
}) => {
    const [busy, setBusy] = React.useState(false);
    const [sent, setSent] = React.useState(false);
    const [error, setError] = React.useState<unknown | null>(null);
    const [validated, setValidated] = React.useState(false);

    const onExited = () => {
        setBusy(false);
        setError(false);
        setValidated(false);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.target as HTMLFormElement;

        if (form?.checkValidity()) {
            setBusy(true);
            setError(null);

            try {
                ReactGA.event('request_login', {});

                const response = await fetch("api/account/login", {
                    body: new FormData(form),
                    method: "POST",
                });

                if (!response.ok) {
                    throw new Error(await response.text());
                }

                setSent(true);
            } catch (error) {
                setError(error);
            } finally {
                setBusy(false);
            }
        }

        setValidated(true);
    };

    return (
        <Modal backdrop={busy ? "static" : true} keyboard={!busy} onExit={onHide} onExited={onExited} onHide={onHide} scrollable show={show} size="lg">
            <Modal.Header closeButton={!busy}>
                <Modal.Title>
                    Login
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form className={`px-3 pb-3 ${validated ? "was-validated" : ""}`} noValidate onSubmit={onSubmit}>

                    <Form.Text as="div" className="mb-4 form-text">
                        Indtast din email for at logge ind
                    </Form.Text>

                    <Form.Group className="mb-3">
                        <Form.Control
                            maxLength={100}
                            name="Email"
                            placeholder="Email*"
                            required
                            type="text" />
                    </Form.Group>

                    <div className="d-flex gap-3 align-items-center">
                        <Button className="h-100" disabled={busy} type="submit" variant="outline-primary">
                            <IconLabel icon={BsLock}>
                                {busy ? "Arbejder..." : "Login"}
                            </IconLabel>
                        </Button>

                        <>{error && (<span className="text-danger">Der skete en fejl. Venligst prøv igen</span>)}</>
                        <>{sent && (<span className="text-success">En mail er sendt med instruktioner</span>)}</>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
