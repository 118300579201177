import * as ReduxToolkit from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { Configuration } from "./types";

import * as Configurator from "./configurator/slice";

export const unloadedState = {
    configurator: Configurator.initialState,
};

export const configureStore = (configuration: Readonly<Configuration | undefined>) => {
    let preloadedState = unloadedState;

    if (configuration) {
        preloadedState = {
            ...preloadedState,
            configurator: {
                ...preloadedState.configurator,
                ...configuration,
                showWizard: false,
            },
        };
    }

    return ReduxToolkit.configureStore({
        preloadedState,
        reducer: {
            configurator: Configurator.reducer,
        },
    });
};

export type AppStore = ReturnType<typeof configureStore>;
export type ApplicationState = ReturnType<AppStore["getState"]>;
export type AppThunkAction<ReturnType = void> = ReduxToolkit.ThunkAction<ReturnType, ApplicationState, unknown, ReduxToolkit.Action<string>>;

export const useAppDispatch = () => useDispatch<AppStore["dispatch"]>();
export const useAppSelector: TypedUseSelectorHook<ApplicationState> = useSelector;