import * as React from "react";
import Button from "react-bootstrap/Button";
import ReactGA from 'react-ga4';

export const DownloadPdf: React.FC<{
    configurationId: string;
    children: React.ReactNode;
    className?: string;
    variant?: string;
}> = ({
    configurationId,
    children,
    className,
    variant,
}) => {
    const [busy, setBusy] = React.useState(false);
    const [error, setError] = React.useState<unknown | null>(null);

    const onSubmit = async () => {
        setBusy(true);
        try {
            ReactGA.event('download_pdf', {
                
            });

            const response = await fetch(`api/pdf/${configurationId}`, {
                method: "POST",
            });

            if (!response.ok) {
                throw new Error(await response.text());
            }

            // https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c
            // Parse Content-Disposition header from response
            const header = response.headers.get("Content-Disposition");
            let fileName = "download.pdf";

            if (header) {
                const contentDispostion = header.split(";");
                const fileNameToken = `filename*=UTF-8''`;

                for (const value of contentDispostion) {
                    if (value.trim().indexOf(fileNameToken) === 0) {
                        const trimmed = value.trim().replace(fileNameToken, "");
                        fileName = decodeURIComponent(trimmed);
                        break;
                    }
                }
            }

            const blob = await response.blob();
            const newBlob = new Blob([blob], { type: "application/pdf" });
            const objUrl = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = objUrl;
            link.download = fileName;
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(objUrl), 250);
        } catch (error) {
            setError(error);
        } finally {
            setBusy(false);
        }
    };

    return (
        <>
            <Button onClick={onSubmit} disabled={busy || !configurationId} className={className} variant={variant}>
                {children}
            </Button>
            {error && (<span className="text-danger">Der skete en fejl. Venligst prøv igen</span>)}
        </>
    );
};
