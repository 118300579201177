import * as React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";

import { Modules } from "./Modules";

import { actions } from "../configurator/slice";
import { StaticDataContext } from "../contexts";
import { useAppDispatch, useAppSelector } from "../store";
import { Form, Modal } from "react-bootstrap";

export const Sidebar = () => {
    const show = useAppSelector(state => state.configurator.systemId !== null);

    if (show) {
        return <SidebarView />;
    } else {
        return null;
    }
};

const SidebarView = () => {
    const { systems } = React.useContext(StaticDataContext);
    const [showResetDialog, setShowResetDialog] = React.useState(false);
    const [showNameDialog, setShowNameDialog] = React.useState(false);
    const [name, setName] = React.useState("");

    const system = useAppSelector(state => systems.find(s => s.id === state.configurator.systemId));

    const dispatch = useAppDispatch();

    const onChangeSystem = React.useCallback(() => dispatch(actions.showWizard()), []);

    const next = React.useCallback(() => {
        setShowResetDialog(false);
        setShowNameDialog(true);
    }, []);

    const onReset = () => {
        if(system !== undefined){
            dispatch(actions.commit({systemId:system.id, templateId: null, templateName: undefined, name}));
        }
        setShowNameDialog(false);
    };

    return (
        <Col as="aside" className="bg-white h-100 shadow-sm" lg={3} xs={4}>
            <div className="px-1 py-2 d-flex flex-column h-100">
                <strong>{system?.name}</strong>

                <div className="d-flex justify-content-between">
                    <Button className="link-primary p-1" variant="link" onClick={onChangeSystem}>
                        Skift system »
                    </Button>
                    <Button className="link-primary p-1" variant="link" onClick={() => setShowResetDialog(true)}>
                        Ny tegning
                    </Button>
                    
                    <Modal backdrop={true} onExit={() => setShowResetDialog(false)} onHide={() => setShowResetDialog(false)} show={showResetDialog} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Ny tegning
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            Er du sikker?<br />
                            <Button onClick={() => next()}>Nulstil</Button>
                        </Modal.Body>
                    </Modal>
                    
                    <Modal backdrop={true} onExit={() => setShowNameDialog(false)} onHide={() => setShowNameDialog(false)} show={showNameDialog} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Ny tegning
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Form.Control
                                maxLength={100}
                                name="Project"
                                value={name}
                                onChange={(e) => setName(e.currentTarget.value)}
                                placeholder="Projekt navn"
                                type="text" />
                            <Button onClick={() => onReset()}>Opret</Button>
                        </Modal.Body>
                    </Modal>
                </div>

                <hr />

                <Modules />
            </div>
        </Col>
    );
};
