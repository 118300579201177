import * as React from "react";
import { ButtonProps } from "react-bootstrap/Button";

export const Shake: React.FC<{ shake?: boolean, children: React.ReactNode }> = ({
    children,
    shake,
}) => {
    const ref = React.useRef<HTMLElement | null>(null);

    React.useEffect(() => {
        if (!shake) {
            return;
        }

        if (!ref.current) {
            return;
        }

        const style = ref.current.style;
        const interval = setInterval(move, 50);

        let px = 6;

        function move() {
            style.transform = `translateX(${px}px)`;
            px = px < 0 ? ((px * -1) - 1) : ((px * -1) + 1);

            if (px === 1) {
                clearInterval(interval);
            }
        }

        return () => {
            clearInterval(interval);
        };
    }, [shake]);

    const child = React.Children.only(children);

    if (child && React.isValidElement(child)) {
        return React.cloneElement(child as React.ReactElement<ButtonProps & {ref: React.MutableRefObject<HTMLElement | null>}>, { ref });
    }

    return null;
};

export const ShakingButton: React.FC<ButtonProps> = (props) => {
    const mounted = React.useRef(true);
    const [busy, setBusy] = React.useState(false);
    const [error, setError] = React.useState(false);

    const onClick: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
        if (!props.onClick) {
            return;
        }

        setBusy(true);
        setError(false);

        try {
            await Promise.resolve(props.onClick(event));
        } catch (error) {
            if (mounted.current) {
                setError(true);
            }

            throw error;
        } finally {
            if (mounted.current || true) {
                setBusy(false);
            }
        }
    };

    const { children, ...rest } = props;
    const child = React.Children.only(children);

    React.useEffect(() => {
        const id = setTimeout(() => {
            if (mounted.current) {
                setError(false);
            }
        }, 1500);

        return () => {
            clearTimeout(id);
        };
    }, [error]);

    React.useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, [])

    return (
        <Shake shake={error}>
            {child && React.isValidElement(child) && React.cloneElement(child as React.ReactElement<ButtonProps>, {
                ...rest,
                disabled: busy,
                onClick,
                variant: error ? "danger" : props.variant,
            })}
        </Shake>
    );
};
