import * as React from "react";

export interface LabelProps {
    className?: string;
    rotationDegrees: number;
    x: number;
    y: number;
    children?: React.ReactNode;
}

export const LabelView: React.FC<LabelProps> = React.memo(({
    children,
    className,
    rotationDegrees,
    x,
    y,
}) => (
    <div
        className={`position-absolute ${className ?? ""}`}
        style={{
            left: `${x}%`,
            transform: `translate(-50%, -50%) rotate(${rotationDegrees}deg)`,
            top: `${y}%`,
            width: 'max-content'
        }}
    >{children}</div>
));

LabelView.displayName = "Label";
