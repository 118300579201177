import * as React from "react";
import { ButtonGroup, ListGroup, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { BsLock } from "react-icons/bs";

import { IconLabel } from "../components/IconLabel";
import { useAppDispatch, useAppSelector } from "../store";
import { actions } from "../configurator/slice";
import { StaticDataContext } from "../contexts";
import { DownloadPdf } from "../downloadPdf/DownloadPdf";

export const Configurations: React.FC<{
    onHide: () => void;
    show: boolean;
}> = ({
    onHide,
    show,
}) => {
    const { systems } = React.useContext(StaticDataContext);

    const system = useAppSelector(state => systems.find(s => s.id === state.configurator.systemId));
    var configs = useAppSelector(state => state.configurator.configurations);
    var dispatch = useAppDispatch();

    React.useEffect(() => {
        if(show){
            dispatch(actions.fetchConfigurations());
        }
    }, [show]);
    
    const clone = async (configurationId: string) => {
        if(system !== undefined){
            await dispatch(actions.commit({systemId:system.id, templateId: null, templateName: undefined, name: ""}));
        }
        
        await dispatch(actions.createConfiguration({sourceConfigurationId: configurationId}));
    };

    return (
        <>
            <Modal onExit={onHide} onHide={onHide} scrollable show={show} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Projekter
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {configs.map(e =>
                                <tr>
                                    <td className="col-md-6">{e.name || e.configurationId}</td>
                                    <td className="col-md-6 text-right">
                                        <Button variant="primary" onClick={() => window.location.assign(`/${e.configurationId}`)}>Åbn</Button>{' '}
                                        <DownloadPdf variant="primary" configurationId={e.configurationId}>Hent PDF</DownloadPdf>{' '}
                                        <Button variant="primary" onClick={() => clone(e.configurationId)}>Dupliker</Button>{' '}
                                        <Button variant="danger" onClick={() => dispatch(actions.removeConfigurationFromMe({configurationId: e.configurationId}))}>Fjern</Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    );
};
