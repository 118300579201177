import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Ratio from "react-bootstrap/Ratio";

export const Help: React.FC<{
    onHide: () => void;
    show: boolean;
}> = ({
    onHide,
    show,
}) => {

    return (
        <Modal onHide={onHide} show={show} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Hjælp
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Ratio aspectRatio="16x9">
                    <iframe
                        src="https://www.youtube-nocookie.com/embed/"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </Ratio>
            </Modal.Body>
        </Modal>
    );
};
