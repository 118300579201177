import * as React from "react";
import Image from "react-bootstrap/Image";

import ConnectorImage from "./samlebox.svg";

export const Connectors: React.FC<{ width: number }> = ({
    width,
}) => (
    <div className="position-relative" style={{ height: 2, marginTop: -1, width: `${width}%` }}>
        <div className="d-flex justify-content-between w-100" style={{ marginTop: -1 }}>
            <Connector />
            <Connector />
        </div>
    </div>
);

const Connector = () => <Image height={4.5} src={ConnectorImage} />;
