import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApplicationState } from "../store";
import { ConfiguredModule, Justification, SavedConfiguration } from "../types";
import ReactGA from 'react-ga4';
import { decodeToken } from 'react-jwt';

export interface AppState {
    configurationId: string | null;
    configurationName: string | undefined;
    configuredModules: ConfiguredModule[];
    selectedConfiguredModuleId: string | null;
    selectedModuleId: string | null;
    showWizard: boolean;
    systemId: string | null;
    justification1stFloor: Justification;
    justification2ndFloor: Justification;
    lengthwiseJustificationGroundFloor: Justification;
    lengthwiseJustification1stFloor: Justification;
    lengthwiseJustification2ndFloor: Justification;
    loggedIn: boolean;
    configurations: SavedConfiguration[];
}

const login = createAsyncThunk(
    "app/login",
    async ({ token }: { token: string }) => {
        localStorage.setItem('token', token);

        var decoded = decodeToken<{unique_name: string}>(token);

        if(decoded){
            ReactGA.gtag('config', 'G-XPXCCDP0XY', {
                'user_id': decoded.unique_name,
                'user_type': decoded.unique_name.includes("@cp.dk") ? 'Internal' : 'external'
            });
        }

        ReactGA.event('login', {});
        
        return token;
    });

const logout = createAsyncThunk(
    "app/logout",
    async (_, {  }) => {
        ReactGA.event('logout', {});

        localStorage.removeItem('token');
    });

const fetchConfigurations = createAsyncThunk(
    "app/loadConfigurations",
    async (_, { getState }) => {
        const state = getState() as ApplicationState;

        const url = `/api/me/configurations`;
        const response = await fetch(url, {
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            method: "GET",
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }

        const configurations = await response.json() as SavedConfiguration[];
        return configurations;
    });

const addConfigurationToMe = createAsyncThunk(
    "app/addConfiguration",
    async ({ configurationId }: { configurationId: string }) => {

        const url = `/api/me/configurations`;
        const response = await fetch(url, {
            body: JSON.stringify({ configurationId: configurationId }),
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            method: "POST",
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }

        const configurations = await response.json() as SavedConfiguration[];
        return configurations;
    });

    const removeConfigurationFromMe = createAsyncThunk(
        "app/removeConfiguration",
        async ({ configurationId }: { configurationId: string }) => {
    
            const url = `/api/me/configurations/${configurationId}`;
            const response = await fetch(url, {
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                method: "DELETE",
            });
    
            if (!response.ok) {
                throw new Error(await response.text());
            }
            
            ReactGA.event('delete_project', {});
    
            const configurations = await response.json() as SavedConfiguration[];
            return configurations;
        });

const createConfiguration = createAsyncThunk(
    "app/createConfiguration",
    async ({ sourceConfigurationId, name }: { sourceConfigurationId?: string, name?: string }, { getState }) => {
        const state = getState() as ApplicationState;
        const { configurationId, systemId } = state.configurator;

        if (configurationId) {
            return configurationId;
        }

        if(sourceConfigurationId){
            const url = `/api/configurations/clone`;
            const response = await fetch(url, {
                body: JSON.stringify({ configurationID: sourceConfigurationId }),
                headers: { "Content-Type": "application/json" },
                method: "POST",
            });
            
            ReactGA.event('clone_project', {

            });

            if (!response.ok) {
                throw new Error(await response.text());
            }
    
            const newConfigurationId = await response.json() as string; 
            window.location.assign(`/${newConfigurationId}`)
            return newConfigurationId;
        }else{
            if (!systemId) {
                throw new Error("System has not been selected");
            }
    
            const url = `/api/configurations`;
            const response = await fetch(url, {
                body: JSON.stringify({ systemId, name }),
                headers: { "Content-Type": "application/json" },
                method: "POST",
            });

            if (!response.ok) {
                throw new Error(await response.text());
            }
            
            ReactGA.event('create_project', {
                system: systemId,
            });
    
            const newConfigurationId = await response.json() as string;
            history.pushState(null, "", `/${newConfigurationId}`);
            return newConfigurationId;
        }

    });

const createLabel = createAsyncThunk(
    "app/createLabel",
    async ({ configuredModuleId, text, x, y, rotationDegrees }: { configuredModuleId: string, text: string, x: number, y: number, rotationDegrees: number }) => {
        const trimmed = text.trim();

        if (trimmed.length === 0) {
            return;
        }

        const url = `/api/configuredmodules/${configuredModuleId}/labels`;

        const response = await fetch(url, {
            body: JSON.stringify({ text: trimmed, x, y, rotationDegrees }),
            headers: { "Content-Type": "application/json" },
            method: "POST",
        });
            
        ReactGA.event('create_label', {});

        if (!response.ok) {
            throw new Error(await response.text());
        }

        return await response.json() as ConfiguredModule[];
    });

const changeJustification = createAsyncThunk(
    "app/changeJustification",
    async ({ floor, justification }: { floor: number, justification: Justification }, { getState }) => {
        const state = getState() as ApplicationState;

        if (!state.configurator.configurationId) {
            return;
        }

        let url = `/api/configurations/${state.configurator.configurationId}/justification/1stfloor`;

        if(floor == 2){
            url = `/api/configurations/${state.configurator.configurationId}/justification/2ndfloor`;
        }

        const response = await fetch(url, {
            body: JSON.stringify({ justification }),
            headers: { "Content-Type": "application/json" },
            method: "POST",
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('change_justification', {
            justification,
        });

        return {floor, justification: await response.json() as Justification};
    });

const changeName = createAsyncThunk(
    "app/changeName",
    async ({ name }: { name: string }, { getState }) => {
        const state = getState() as ApplicationState;

        if (!state.configurator.configurationId) {
            return {name};
        }

        let url = `/api/configurations/${state.configurator.configurationId}/name`;

        const response = await fetch(url, {
            body: JSON.stringify({ name }),
            headers: { "Content-Type": "application/json" },
            method: "POST",
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }

        return {name};
    });

const changeLengthwiseJustification = createAsyncThunk(
    "app/changeLengthwiseJustification",
    async ({ floor, justification }: { floor: number, justification: Justification }, { getState }) => {
        const state = getState() as ApplicationState;

        if (!state.configurator.configurationId) {
            return;
        }

        let url = `/api/configurations/${state.configurator.configurationId}/lengthwiseJustification/groundfloor`;

        if(floor == 1){
            url = `/api/configurations/${state.configurator.configurationId}/lengthwiseJustification/1stfloor`;
        }
        if(floor == 2){
            url = `/api/configurations/${state.configurator.configurationId}/lengthwiseJustification/2ndfloor`;
        }

        const response = await fetch(url, {
            body: JSON.stringify({ justification }),
            headers: { "Content-Type": "application/json" },
            method: "POST",
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('change_lengthwise_justification', {
            justification,
        });

        return {floor, justification: await response.json() as Justification};
    });

const customizeModule = createAsyncThunk(
    "app/customizeModule",
    async ({ configuredModuleId, hotspotId, optionId, }: { configuredModuleId: string, hotspotId: string, optionId: string | null }, { getState }) => {
        const state = getState() as ApplicationState;

        if (!state.configurator.configurationId) {
            return;
        }

        const module = state.configurator.configuredModules.find(e => e.id === configuredModuleId);

        const url = `/api/configuredmodules/${configuredModuleId}/customize`;

        const response = await fetch(url, {
            body: JSON.stringify({ hotspotId, optionId }),
            headers: { "Content-Type": "application/json" },
            method: "POST",
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('change_customization', {
            module: module?.moduleId,
            hotspot: hotspotId,
            option: optionId ?? '-none-',
        });

        return await response.json() as ConfiguredModule[];
    });

const rotate = createAsyncThunk(
    "app/rotate",
    async ({ customizationId }: { customizationId: string }, { getState }) => {
        const state = getState() as ApplicationState;

        if (!state.configurator.configurationId) {
            return;
        }

        const customization = state.configurator.configuredModules.flatMap(e => e.customizations).find(e => e.id == customizationId);
        const module = state.configurator.configuredModules.find(e => e.customizations.some(x => x.id == customizationId));

        let url = `/api/customizations/${customizationId}/flipx`;

        let response = await fetch(url, { method: "POST" });

        if (!response.ok) {
            throw new Error(await response.text());
        }

        url = `/api/customizations/${customizationId}/flipy`;

        response = await fetch(url, { method: "POST" });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('rotate_customization', {
            module: module?.moduleId,
            hotspot: customization?.hotspotId,
            customization: customization?.optionId
        });

        return await response.json() as ConfiguredModule[];
    });

const flipX = createAsyncThunk(
    "app/flipX",
    async ({ customizationId }: { customizationId: string }, { getState }) => {
        const state = getState() as ApplicationState;

        if (!state.configurator.configurationId) {
            return;
        }

        const customization = state.configurator.configuredModules.flatMap(e => e.customizations).find(e => e.id == customizationId);
        const module = state.configurator.configuredModules.find(e => e.customizations.some(x => x.id == customizationId));

        const url = `/api/customizations/${customizationId}/flipx`;

        const response = await fetch(url, { method: "POST" });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('flip_customization', {
            axis: 'x',
            module: module?.moduleId,
            hotspot: customization?.hotspotId,
            customization: customization?.optionId
        });

        return await response.json() as ConfiguredModule[];
    });

const flipY = createAsyncThunk(
    "app/flipY",
    async ({ customizationId }: { customizationId: string }, { getState }) => {
        const state = getState() as ApplicationState;

        if (!state.configurator.configurationId) {
            return;
        }

        const customization = state.configurator.configuredModules.flatMap(e => e.customizations).find(e => e.id == customizationId);
        const module = state.configurator.configuredModules.find(e => e.customizations.some(x => x.id == customizationId));

        const url = `/api/customizations/${customizationId}/flipy`;

        const response = await fetch(url, { method: "POST" });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('flip_customization', {
            axis: 'y',
            module: module?.moduleId,
            hotspot: customization?.hotspotId,
            customization: customization?.optionId
        });

        return await response.json() as ConfiguredModule[];
    });

const duplicateModule = createAsyncThunk(
    "app/duplicateModule",
    async ({ configuredModuleId }: { configuredModuleId: string }, { getState }) => {
        const state = getState() as ApplicationState;
        const { configurationId } = state.configurator;

        if (!configurationId) {
            return;
        }

        const module = state.configurator.configuredModules.find(e => e.id === configuredModuleId);

        const url = `/api/configuredmodules/${configuredModuleId}/duplicate`;
        const response = await fetch(url, { method: "POST" });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('duplicate_module', {
            module: module?.moduleId
        });

        return await response.json() as ConfiguredModule[];
    });

const moveModule = createAsyncThunk(
    "app/moveModule",
    async ({ configuredModuleId, distance }: { configuredModuleId: string, distance: number }, { getState }) => {
        const state = getState() as ApplicationState;
        const { configurationId } = state.configurator;

        if (!configurationId) {
            return;
        }

        const module = state.configurator.configuredModules.find(e => e.id === configuredModuleId);

        const url = `/api/configuredmodules/${configuredModuleId}/move`;
        const response = await fetch(url, {
            body: JSON.stringify({ distance }),
            headers: { "Content-Type": "application/json" },
            method: "POST"
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('move_module', {
            module: module?.moduleId
        });

        return await response.json() as ConfiguredModule[];
    });

const insertFurniture = createAsyncThunk(
    "app/insertFurniture",
    async ({ configuredModuleId, furnitureId, x, y, rotation }: { configuredModuleId: string, furnitureId: string, x: number, y: number, rotation: number }, { getState }) => {
        const state = getState() as ApplicationState;

        if (!state.configurator.configurationId) {
            return;
        }

        const module = state.configurator.configuredModules.find(e => e.id === configuredModuleId);

        const url = `/api/configuredmodules/${configuredModuleId}/furniture`;
        const response = await fetch(url, {
            body: JSON.stringify({ furnitureId, x, y, rotation }),
            headers: { "Content-Type": "application/json" },
            method: "POST"
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('insert_furniture', {
            module: module?.moduleId,
            furniture: furnitureId
        });

        return await response.json() as ConfiguredModule[];
    });

const insertSelectedModule = createAsyncThunk(
    "app/insertSelectedModule",
    async ({ floor, index }: { floor: number, index: number }, { dispatch, getState }) => {
        const state = getState() as ApplicationState;
        const { selectedModuleId } = state.configurator;
        let { configurationId } = state.configurator;

        if (!configurationId) {
            configurationId = await dispatch(createConfiguration({name:state.configurator.configurationName})).unwrap();

            if (!configurationId) {
                return;
            }
        }

        const url = `/api/configuredmodules`;
        const response = await fetch(url, {
            body: JSON.stringify({ configurationId, floor, index, moduleId: selectedModuleId }),
            headers: { "Content-Type": "application/json" },
            method: "POST",
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('insert_module', {
            module: selectedModuleId,
        });

        return await response.json() as ConfiguredModule[];
    });

const moveFurniture = createAsyncThunk(
    "app/moveFurniture",
    async ({ placedFurnitureId, x, y }: { placedFurnitureId: string, x: number, y: number }, { getState }) => {
        const state = getState() as ApplicationState;

        if (!state.configurator.configurationId) {
            return;
        }
        
        const furniture = state.configurator.configuredModules.flatMap(e => e.placedFurnitures).find(e => e.id == placedFurnitureId);
        const module = state.configurator.configuredModules.find(e => e.placedFurnitures.some(x => x.id == placedFurnitureId));

        const response = await fetch(`/api/furnitures/${placedFurnitureId}/move`, {
            body: JSON.stringify({ x, y }),
            headers: { "Content-Type": "application/json" },
            method: "POST"
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('move_furniture', {
            furniture: furniture?.furnitureId,
            module: module?.moduleId,
        });

        return await response.json() as ConfiguredModule[];
    });

const removeFurniture = createAsyncThunk(
    "app/removeFurniture",
    async ({ placedFurnitureId }: { placedFurnitureId: string }, { getState }) => {
        const state = getState() as ApplicationState;
        const { configurationId } = state.configurator;

        if (!configurationId) {
            return;
        }
        
        const furniture = state.configurator.configuredModules.flatMap(e => e.placedFurnitures).find(e => e.id == placedFurnitureId);
        const module = state.configurator.configuredModules.find(e => e.placedFurnitures.some(x => x.id == placedFurnitureId));

        const url = `/api/furnitures/${placedFurnitureId}`;
        const response = await fetch(url, { method: "DELETE" });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('delete_furniture', {
            furniture: furniture?.furnitureId,
            module: module?.moduleId,
        });

        return await response.json() as ConfiguredModule[];
    });

const removeFloor = createAsyncThunk(
    "app/removeFloor",
    async ({ floor }: { floor: number }, { getState }) => {
        const state = getState() as ApplicationState;
        const { configurationId } = state.configurator;

        if (!configurationId) {
            return;
        }

        const url = `/api/configurations/${configurationId}/${floor}`;
        const response = await fetch(url, { method: "DELETE" });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('delete_floor', {
            floor
        });

        return await response.json() as ConfiguredModule[];
    });

const removeModule = createAsyncThunk(
    "app/removeModule",
    async ({ configuredModuleId }: { configuredModuleId: string }, { getState }) => {
        const state = getState() as ApplicationState;
        const { configurationId } = state.configurator;

        if (!configurationId) {
            return;
        }

        const module = state.configurator.configuredModules.find(e => e.id == configuredModuleId);

        const url = `/api/configurations/${configurationId}/${configuredModuleId}`;
        const response = await fetch(url, { method: "DELETE" });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('delete_module', {
            module: module?.moduleId,
            floor: module?.floor
        });

        return await response.json() as ConfiguredModule[];
    });

const replaceModule = createAsyncThunk(
    "app/replaceModule",
    async ({ configuredModuleId }: { configuredModuleId: string }, { getState }) => {
        const state = getState() as ApplicationState;
        const { configurationId, selectedModuleId } = state.configurator;

        if (!configurationId) {
            return;
        }

        const module = state.configurator.configuredModules.find(e => e.id == configuredModuleId);

        const url = `/api/configuredmodules/${configuredModuleId}/replace`;
        const response = await fetch(url, {
            body: JSON.stringify({ moduleId: selectedModuleId }),
            headers: { "Content-Type": "application/json" },
            method: "POST",
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('replace_module', {
            oldmodule: module?.moduleId,
            newmodule: selectedModuleId,
            floor: module?.floor
        });

        return await response.json() as ConfiguredModule[];
    });

const rotateFurniture = createAsyncThunk(
    "app/rotateFurniture",
    async ({ placedFurnitureId, rotationDegrees }: { placedFurnitureId: string, rotationDegrees: number }, { getState }) => {
        const state = getState() as ApplicationState;

        if (!state.configurator.configurationId) {
            return;
        }
        
        const furniture = state.configurator.configuredModules.flatMap(e => e.placedFurnitures).find(e => e.id == placedFurnitureId);
        const module = state.configurator.configuredModules.find(e => e.placedFurnitures.some(x => x.id == placedFurnitureId));

        const url = `/api/furnitures/${placedFurnitureId}/rotate`;
        const response = await fetch(url, {
            body: JSON.stringify({ rotationDegrees }),
            headers: { "Content-Type": "application/json" },
            method: "POST"
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('rotate_furniture', {
            furniture: furniture?.furnitureId,
            module: module?.moduleId,
            rotation: rotationDegrees,
        });

        return await response.json() as ConfiguredModule[];
    });

const rotateModule = createAsyncThunk(
    "app/rotateModule",
    async ({ configuredModuleId }: { configuredModuleId: string }, { getState }) => {
        const state = getState() as ApplicationState;
        const { configurationId } = state.configurator;

        if (!configurationId) {
            return;
        }

        const module = state.configurator.configuredModules.find(e => e.id == configuredModuleId);

        const url = `/api/configuredmodules/${configuredModuleId}/rotate`;
        const response = await fetch(url, { method: "POST" });

        if (!response.ok) {
            throw new Error(await response.text());
        }
            
        ReactGA.event('rotate_module', {
            module: module?.moduleId,
            floor: module?.floor
        });

        return await response.json() as ConfiguredModule[];
    });

const updateLabel = createAsyncThunk(
    "app/updateLabel",
    async ({ labelId, text, x, y, rotationDegrees }: { labelId: string, text: string, x: number, y: number, rotationDegrees: number }) => {
        const response = await fetch(`/api/labels/${labelId}`, {
            body: JSON.stringify({ text, x, y, rotationDegrees }),
            headers: { "Content-Type": "application/json" },
            method: "POST",
        });

        if (response.ok) {
            return await response.json() as ConfiguredModule[];
        }

        throw new Error(await response.text());
    });

const commit = createAsyncThunk(
    "app/commit",
    async ({ templateId, name, systemId, templateName }: { systemId: string, templateId: number | null, templateName: string | undefined, name: string }, { getState }) => {
        history.pushState(null, "", "/");

        if (templateId) {
            const response = await fetch(`/api/templates/${templateId}/copy`, {
                body: JSON.stringify({ name }),
                headers: { "Content-Type": "application/json" },
                method: "POST",
            });

            if (response.ok) {
                const body = await response.json() as { configurationId: string, configuredModules: ConfiguredModule[], projectName: string };
                history.pushState(null, "", `/${body.configurationId}`);
            
                ReactGA.event('create_project', {
                    system: systemId,
                    templateId,
                    templateName
                });

                return body;
            }

            throw new Error(await response.text());
        } else {

            ReactGA.event('create_project', {
                system: systemId
            });

            return { configurationId: null, configuredModules: [], projectName: name };
        }
    });

export const initialState: AppState = {
    configurationId: null,
    configurationName: "",
    configuredModules: [],
    selectedConfiguredModuleId: null,
    selectedModuleId: null,
    showWizard: true,
    systemId: null,
    justification1stFloor: 'Left',
    justification2ndFloor: 'Left',
    lengthwiseJustificationGroundFloor: 'Left',
    lengthwiseJustification1stFloor: 'Left',
    lengthwiseJustification2ndFloor: 'Left',
    configurations: [],
    loggedIn: false,
};

const slice = createSlice({
    name: "app",
    initialState,
    reducers: {
        hideWizard(state) {
            state.showWizard = false;
        },

        selectConfiguredModule(state, action: PayloadAction<string | null>) {
            state.selectedConfiguredModuleId = state.selectedConfiguredModuleId === action.payload ? null : action.payload;
            state.selectedModuleId = null;
        },

        selectModule(state, action: PayloadAction<string>) {
            state.selectedConfiguredModuleId = null;
            state.selectedModuleId = state.selectedModuleId === action.payload ? null : action.payload;
        },

        showWizard(state) {
            state.selectedModuleId = null;
            state.showWizard = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.loggedIn = true;
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.loggedIn = false;
            })
            .addCase(fetchConfigurations.fulfilled, (state, action) => {
                state.configurations = action.payload;
            })
            .addCase(addConfigurationToMe.fulfilled, (state, action) => {
                state.configurations = action.payload;
            })
            .addCase(removeConfigurationFromMe.fulfilled, (state, action) => {
                state.configurations = action.payload;
            })
            .addCase(commit.fulfilled, (state, action) => {
                state.configurationId = action.payload.configurationId;
                state.configurationName = action.payload.projectName;
                state.configuredModules = action.payload.configuredModules;
                state.selectedModuleId = null;
                state.systemId = action.meta.arg.systemId;
            })
            .addCase(createConfiguration.fulfilled, (state, action) => {
                state.configurationId = action.payload;
                state.configurationName = action.meta.arg.name;
                state.justification1stFloor = 'Left';
                state.justification2ndFloor = 'Left';
                state.lengthwiseJustificationGroundFloor = 'Left';
                state.lengthwiseJustification1stFloor = 'Left';
                state.lengthwiseJustification2ndFloor = 'Left';
            })
            .addCase(changeJustification.fulfilled, (state, action) => {
                if(action.payload?.floor == 1){
                    state.justification1stFloor = action.payload!.justification;
                }else if(action.payload?.floor == 2){
                    state.justification2ndFloor = action.payload!.justification;
                }
            })
            .addCase(changeName.fulfilled, (state, action) => {
                state.configurationName = action.payload?.name;
            })
            .addCase(changeLengthwiseJustification.fulfilled, (state, action) => {
                if(action.payload?.floor == 0){
                    state.lengthwiseJustificationGroundFloor = action.payload!.justification;
                }else if(action.payload?.floor == 1){
                    state.lengthwiseJustification1stFloor = action.payload!.justification;
                }else if(action.payload?.floor == 2){
                    state.lengthwiseJustification2ndFloor = action.payload!.justification;
                }
            })
            .addCase(createLabel.pending, (state, action) => {
                const { configuredModuleId, text, x, y, rotationDegrees } = action.meta.arg;
                const configuredModule = state.configuredModules.find(cm => cm.id === configuredModuleId);

                if (configuredModule) {
                    configuredModule.labels.push({ id: action.meta.requestId, text, x, y, rotationDegrees });
                }
            })
            .addCase(createLabel.rejected, (state, action) => {
                const { configuredModuleId } = action.meta.arg;
                const configuredModule = state.configuredModules.find(cm => cm.id === configuredModuleId);

                if (configuredModule) {
                    const labels = configuredModule.labels;
                    const index = labels.findIndex(l => l.id === action.meta.requestId);

                    if (index !== -1) {
                        labels.splice(index, 1);
                    }
                }
            })
            .addCase(createLabel.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            })
            .addCase(customizeModule.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            })
            .addCase(flipX.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            })
            .addCase(flipY.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            })
            .addCase(rotate.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            })
            .addCase(duplicateModule.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            })
            .addCase(moveModule.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            })
            .addCase(insertFurniture.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload
                }
            })
            .addCase(insertSelectedModule.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                    state.selectedModuleId = null;
                }
            })
            .addCase(moveFurniture.pending, (state, action) => {
                for (const cm of state.configuredModules) {
                    for (const pf of cm.placedFurnitures) {
                        if (pf.id === action.meta.arg.placedFurnitureId) {
                            pf.x = action.meta.arg.x;
                            pf.y = action.meta.arg.y;
                            return;
                        }
                    }
                }
            })
            .addCase(moveFurniture.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            })
            .addCase(removeFloor.pending, (state) => {
                state.selectedConfiguredModuleId = null;
            })
            .addCase(removeFloor.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                    state.selectedConfiguredModuleId = null;
                }
            })
            .addCase(removeFurniture.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            })
            .addCase(removeModule.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                    state.selectedConfiguredModuleId = null;
                }
            })
            .addCase(replaceModule.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload!;
                    state.selectedModuleId = null;
                }
            })
            .addCase(rotateFurniture.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            })
            .addCase(rotateModule.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            })
            .addCase(updateLabel.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configuredModules = action.payload;
                }
            });
    },
});

export const actions = {
    ...slice.actions,
    createConfiguration,
    changeJustification,
    changeName,
    changeLengthwiseJustification,
    login,
    logout,
    fetchConfigurations,
    addConfigurationToMe,
    removeConfigurationFromMe,
    commit,
    createLabel,
    customizeModule,
    flipX,
    flipY,
    rotate,
    duplicateModule,
    moveModule,
    insertFurniture,
    insertSelectedModule,
    moveFurniture,
    removeFloor,
    removeFurniture,
    removeModule,
    replaceModule,
    rotateFurniture,
    rotateModule,
    updateLabel,
};

export const reducer = slice.reducer;
